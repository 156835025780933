import { useMatch } from "react-router-dom";

function useUsername() {
  const profileMatch = useMatch("/profile/:userName");

  const username = profileMatch?.params.userName || null;

  return username;
}

export default useUsername;