import React from "react";
import { NavLink } from "react-router-dom";

import Supporter from "../Types/Supporter";
import Flag from "./Flag";
import { AMOUNT_4 } from "../Lib/SupporterUtils";

type Props = {
  country?: string;
  supporters?: Supporter[];
  showFlag?: boolean;
  username: string;
};

const Username: React.FC<Props> = ({
  country,
  showFlag,
  supporters,
  username,
}) => {
  const supporter = supporters?.find((s) => s.name === username);
  const isSupporter = !!supporter;
  const isVIP = isSupporter && supporter.amount === AMOUNT_4;
  const title = isSupporter
    ? `${username} is a Turf Medals ${isVIP ? "VIP" : "supporter"}!`
    : "";
  return (
    <>
      <NavLink to={`/profile/${encodeURIComponent(username)}`}>
        <span
          className={isSupporter ? "donator-highlight" : undefined}
          title={title}
        >
          {username}
        </span>
        {isSupporter && supporter.amount === AMOUNT_4 && (
          <span title={title}> {AMOUNT_4}</span>
        )}
      </NavLink>
      {showFlag && country && <Flag className="ml-1" country={country} small />}
    </>
  );
};

export default Username;
