import { useContext } from "react";
import MedalsContext from "../Store/MedalsDataContext";

const useMedalsData = () => {
  const medals = useContext(MedalsContext);

  return medals;
};

export default useMedalsData;
