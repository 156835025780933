import React from "react";
import { Icon } from "tabler-react";

type Props = {
  className?: string;
  country: string;
  small?: boolean;
};

const Flag: React.FC<Props> = ({ className, country, small = false }) => (
  <Icon
    className={[small ? "flag-small" : undefined, className]}
    flag={country.indexOf("wd") === -1}
    name={country.replace("uk", "gb").replace("wd", "globe")}
  />
);

export default Flag;
