import React from "react";
import { Icon } from "tabler-react";
import { MedalGroup } from "../Types/MedalTypes";
import {
  getCurrentDataForMedalGroup,
  getCurrentMedal,
  getNextMedal,
  getProgressUntilNext,
} from "../Utils";
import UserData from "../Types/UserData";
import useFirebase from "../Hooks/useFirebase";
import { monumentZonesRef } from "../Services/FirebaseDB";
import ZonesByTypeZone from "../Types/ZonesByTypeZone";

type RemainingProps = {
  medalGroup: MedalGroup;
  userData: UserData;
};

const Remaining: React.FC<RemainingProps> = ({ medalGroup, userData }) => {
  const curMedal = getCurrentMedal(medalGroup, userData.medals);
  const nextMedal = getNextMedal(medalGroup, userData.medals);
  const completed = curMedal != null && nextMedal == null;
  const monumentZones =
    useFirebase<Record<string, ZonesByTypeZone>>(monumentZonesRef);
  const current = getCurrentDataForMedalGroup(
    medalGroup,
    userData,
    completed,
    monumentZones
  );

  if (completed) {
    return (
      <span className="medal-done text-green" title="Completed">
        <Icon name="check" />
        {current?.value &&
          medalGroup.includeCurrentDataWhenCompleted !== false && (
            <span className="medal-done-value" title={current.desc || ""}>
              {current.value}
              {current.entity != null &&
                ` ${current.entity}${current.value === 1 ? "" : "s"}`}
            </span>
          )}
      </span>
    );
  }

  if (current?.text) {
    return <span className="text-muted">{current.text}</span>;
  }

  if (current == null || nextMedal?.value == null) return null;

  const userValue = current.value ?? 0;
  const nextValue = parseInt(nextMedal.value, 10);

  const desc = `${nextValue} - ${current.value ?? 0}${
    current.desc ? ` (${current.desc})` : ""
  }`;

  const progressWidth = getProgressUntilNext(userValue, 0, nextValue);
  const left = nextValue - userValue;

  return (
    <div title={desc}>
      <div>
        <abbr>
          {left?.toLocaleString() ?? "-"}
          {current.entity != null &&
            ` ${current.entity}${left === 1 ? "" : "s"}`}
        </abbr>
        {current.desc && <abbr title={current.desc}> *</abbr>}
      </div>
      <div className="progress progress-sm">
        <div
          className={`progress-bar ${
            progressWidth > 50 ? "bg-green" : "bg-yellow"
          }`}
          style={{
            width: `${progressWidth}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(Remaining);
