import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAFvQT_wB030l7vacpRoG8S2xBFBeO1OnA",
  appId: "1:673127880840:web:951004328143b34191673d",
  authDomain: "turf-medals.firebaseapp.com",
  databaseURL:
    "https://turf-medals-default-rtdb.europe-west1.firebasedatabase.app",
  messagingSenderId: "673127880840",
  projectId: "turf-medals",
  storageBucket: "turf-medals.appspot.com",
};

const app = initializeApp(firebaseConfig);

export default app;
