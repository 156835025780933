import React from "react";
import UserData from "../../Types/UserData";
import Ranks from "../../Lib/Ranks";
import { getProgressUntilNext } from "../../Utils";
import TooltipCard from "./TooltipCard";

interface Props {
  userData: UserData;
}
const RankCard: React.FC<Props> = ({ userData }) => {
  const rank = Ranks[userData.rank];
  const nextRank = Ranks[userData.rank + 1];
  const pointsRemaining = nextRank ? nextRank.points - userData.totalPoints : 0;
  return (
    <TooltipCard
      content={
        <>
          <div className="">Rank</div>
          <div className="h1 font-weight-bold m-0" title={rank?.name}>
            {userData.rank}
          </div>
          <div className="mb-2">{rank?.name}</div>
          {rank && nextRank && (
            <div
              className="pointer progress progress-sm"
              title={`${pointsRemaining.toLocaleString()} points until next rank (${
                nextRank.name
              } @ ${nextRank.points.toLocaleString()} points)`}
            >
              <div
                className="progress-bar bg-green"
                style={{
                  width: `${getProgressUntilNext(
                    userData.totalPoints,
                    rank.points,
                    nextRank.points
                  )}%`,
                }}
              ></div>
            </div>
          )}
        </>
      }
      tooltipContent={
        rank &&
        nextRank && (
          <>
            <h5 className="mb-0">Next Rank: {userData.rank + 1}</h5>
            {nextRank.name}<br /><br />
            <small>
              Required points: {nextRank.points.toLocaleString()}
              <br />
              Current points: {userData.totalPoints.toLocaleString()}
              <br />
              <strong>Remaining: {pointsRemaining.toLocaleString()}</strong>
            </small>
          </>
        )
      }
    />
  );
};

export default RankCard;
