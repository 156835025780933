import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Icon, PricingCard } from "tabler-react";
import CompareSearchHeaderCard from "../Components/CompareSearchHeaderCard";
import CompareUserHeaderCard from "../Components/CompareUserHeaderCard";
import MedalItem from "../Components/MedalItem";
import { getResults, getPosition, CompareItems } from "../Lib/CompareUtils";
import { LAST_VIEWED_PROFILE_KEY } from "../Lib/Constants";
import { fetchTurfgameUser } from "../Services/UserService";
import UserData from "../Types/UserData";
import useMedalsData from "../Hooks/useMedalsData";

type Props = {};

const Compare: React.FC<Props> = () => {
  const [calculating, setCalculating] = useState(false);
  const [calcAnimTopIndex, setCalcAnimTopIndex] = useState(0);
  const [calcAnimGroupIndex, setCalcAnimGroupIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserData[]>([{} as UserData]);
  const calcAnimInterval = useRef<NodeJS.Timeout | null>(null);
  const { singleMedals } = useMedalsData();

  const sumWeights = useMemo<number>(
    () => Object.values(CompareItems).reduce((sum, i) => sum + i.weight, 0),
    []
  );

  const { userPercentages, winnerId } = useMemo(
    () => getResults(users),
    [users]
  );

  const startAnimation = () => {
    setCalculating(true);
    setTimeout(() => {
      if (calcAnimInterval.current) {
        clearInterval(calcAnimInterval.current);
        setCalcAnimTopIndex(-1);
        setTimeout(() => {
          setCalculating(false);
        }, 300);
      }
    }, 3500);
  };

  const fetchUser = (username: string, index: number) => {
    setLoading(true);
    if (users[index]) {
      users[index].name = "Loading ...";
    }

    fetchTurfgameUser(username)
      .then((user) => {
        const existingUsers = [...users];
        existingUsers[index] = user;
        setUsers(existingUsers);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearUser = (index: number) => {
    const existingUsers = [...users].filter((_, i) => i !== index);
    setUsers(existingUsers);
  };

  useEffect(() => {
    if (calcAnimInterval.current) {
      clearInterval(calcAnimInterval.current);
      calcAnimInterval.current = null;
    }

    if (calculating && !calcAnimInterval.current) {
      calcAnimInterval.current = setInterval(() => {
        const nextIndex =
          calcAnimTopIndex + 1 >= users.length ? 0 : calcAnimTopIndex + 1;
        setCalcAnimTopIndex(nextIndex);
        if (nextIndex % 3 === 0) setCalcAnimGroupIndex(calcAnimGroupIndex + 1);
      }, 300);
    }

    if (!calculating) {
      setCalcAnimGroupIndex(-1);
      setCalcAnimTopIndex(-1);
    }
  }, [calculating, calcAnimGroupIndex, calcAnimTopIndex, users.length]);

  useEffect(() => {
    const newValidUsers = users.filter((u) => u?.id);
    if (newValidUsers.length > 1) {
      startAnimation();
    } else if (calcAnimInterval.current) {
      clearInterval(calcAnimInterval.current);
    }
  }, [users]);

  useEffect(() => {
    document.title = `Compare - Turf Medals`;
    const lastViewedUser = localStorage.getItem(LAST_VIEWED_PROFILE_KEY);
    if (lastViewedUser) {
      fetchUser(lastViewedUser, 0);
    }
  }, []);

  const hasValidResults = users.length > 1;

  return (
    <div className="compare">
      <div className="turn text-center">
        <img src="/turn.png" alt="Turn" />
      </div>
      <Grid.Row>
        <Grid.Col sm={3} lg={3}>
          <PricingCard className="compare-turfer">
            <PricingCard.Category>{"Turfer"}</PricingCard.Category>
            <PricingCard.Price className="compare-category-graphic">
              <Icon name="user" />
            </PricingCard.Price>
          </PricingCard>
        </Grid.Col>

        {users.map((user, i) => {
          const active =
            (winnerId === user?.id && !calculating) ||
            (calculating && calcAnimTopIndex === i);
          return (
            <Grid.Col key={user?.id || i} sm={3} lg={3}>
              <CompareUserHeaderCard
                active={active}
                calculating={calculating}
                index={i}
                user={user}
                userPercent={
                  hasValidResults && userPercentages && userPercentages[i]
                    ? userPercentages[i]
                    : undefined
                }
                userResetter={clearUser}
                winner={winnerId === user?.id}
              />
            </Grid.Col>
          );
        })}
        {users.length < 3 && (
          <Grid.Col sm={3} lg={3}>
            <CompareSearchHeaderCard
              calculating={calculating}
              index={users.length}
              userFetcher={fetchUser}
              userResetter={clearUser}
            />
          </Grid.Col>
        )}
      </Grid.Row>

      {CompareItems.map((item, i) => (
        <Grid.Row key={item.name}>
          <Grid.Col sm={3} lg={3}>
            <PricingCard>
              <PricingCard.Category>{item.name}</PricingCard.Category>
              <PricingCard.Price className="compare-category-graphic">
                {item.icon && <Icon name={item.icon} />}
                {item.medal && <MedalItem medal={singleMedals[item.medal]} />}
              </PricingCard.Price>
              <PricingCard.AttributeList>
                <PricingCard.AttributeItem>
                  <span className="text-muted">
                    {`Weight: ${
                      Math.round((10 * 100 * item.weight) / sumWeights) / 10
                    } %`}
                  </span>
                </PricingCard.AttributeItem>
                {item.description && (
                  <PricingCard.AttributeItem>
                    {item.description}
                  </PricingCard.AttributeItem>
                )}
              </PricingCard.AttributeList>
            </PricingCard>
          </Grid.Col>

          {users.map((user) => {
            if (!user || !user.id || !hasValidResults) return null;
            const pos = getPosition(user, users, item.dataGetter, item.inverse);
            const data = item.dataGetter(user, users);
            const active = pos === 1;

            return (
              <Grid.Col
                className={calculating && i > calcAnimGroupIndex ? "hide" : ""}
                key={user.id}
                lg={3}
                sm={3}
              >
                <PricingCard active={active}>
                  <PricingCard.Category> </PricingCard.Category>
                  <PricingCard.Price
                    className={
                      active ? "text-green text-bold display-4" : "display-4"
                    }
                  >
                    {data.prefix && data.prefix}
                    {data.name ? data.name : data.value.toLocaleString()}
                  </PricingCard.Price>
                  {data.medals && (
                    <PricingCard.AttributeList>
                      <PricingCard.AttributeItem>
                        <div>
                          {data.medals.map((m) => (
                            <MedalItem key={m.id} medal={m} />
                          ))}
                        </div>
                      </PricingCard.AttributeItem>
                    </PricingCard.AttributeList>
                  )}
                </PricingCard>
              </Grid.Col>
            );
          })}
        </Grid.Row>
      ))}
    </div>
  );
};

export default Compare;
