import { useEffect, useState } from "react";
import { DatabaseReference, onValue } from "firebase/database";

export const useFirebase = <T>(ref: DatabaseReference) => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const fetchData = () => {
      onValue(ref, (snapshot) => {
        setData(snapshot.val());
      });
    };

    fetchData();
  }, []);

  return data;
};

export default useFirebase;
