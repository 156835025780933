import { useEffect, useState } from "react";
import { onValue } from "firebase/database";

import { supportersRef } from "../Services/FirebaseDB";
import Supporter from "../Types/Supporter";

export const useSupporters = () => {
  const [supporters, setSupporters] = useState<Supporter[]>([]);

  useEffect(() => {
    const fetchSupporters = () => {
      onValue(supportersRef, (snapshot) => {
        const supportersData = snapshot.val();
        const supporters = Object.keys(supportersData).map(
          (key) => supportersData[key] as Supporter
        );
        setSupporters(supporters);
      });
    };

    fetchSupporters();
  }, []);

  return supporters;
};
