import React from "react";

const IMAGES = [
  {
    url: "https://warded.se/turf/img/toplists/1globaltoplist.png",
    title: "Total Points",
  },
  {
    url: "https://warded.se/turf/img/toplists/3gamepointtoplist.png",
    title: "Round Points",
  },
  {
    url: "https://warded.se/turf/img/toplists/4gametakestoplist.png",
    title: "Number of Takes",
  },
  {
    url: "https://warded.se/turf/img/toplists/6regiontoplist.png",
    title: "Number of visited regions",
  },
  {
    url: "https://warded.se/turf/img/toplists/11roundtakestoplist.png",
    title: "Round takes",
  },
  {
    url: "https://warded.se/turf/img/toplists/14assiststoplist.png",
    title: "Number of Assists",
  },
  {
    url: "https://warded.se/turf/img/toplists/15assistedturferstoplist.png",
    title: "Number of Assisted Turfers",
  },
  {
    url: "https://warded.se/turf/img/toplists/12takeoversydaytoplist.png",
    title: "Takes yesterday",
  },
  {
    url: "https://warded.se/turf/img/toplists/8uniquetoplist.png",
    title: "Number of Unique Zones Taken",
  },
  {
    url: "https://warded.se/turf/img/toplists/16zonesheldtoplist.png",
    title: "Number of zones held at the same time",
  },
];

const TopGraphs: React.FC = () => {
  return (
    <div>
      <h3>Toplists</h3>
      <p>
        Source: <a href="https://warded.se/turf/toplist.php">Warded.se</a>
      </p>
      {IMAGES.map((img) => (
        <div className="mb-4" key={img.url}>
          <img key={img.url} src={img.url} title={img.title} alt={img.title} />
        </div>
      ))}
    </div>
  );
};

export default React.memo(TopGraphs);
