import React, { useEffect, useState } from "react";
import { Icon, Nav, Site } from "tabler-react";
import { Link, NavLink, useMatch, useNavigate } from "react-router-dom";

import DonateButton from "../Components/Cards/DonateButton";
import SupportersFooter from "../Components/SupportersFooter";
import { LAST_VIEWED_PROFILE_KEY } from "../Lib/Constants";

type subNavItem = {
  value: string;
  to?: string;
  icon?: string;
  LinkComponent?: React.ElementType;
  useExact?: boolean;
};

type navItem = {
  value: string;
  to?: string;
  icon?: string;
  active?: boolean;
  LinkComponent?: React.ElementType;
  subItems?: Array<subNavItem>;
  useExact?: string;
};

const navBarItems: Array<navItem> = [
  {
    value: "Home",
    to: "/",
    icon: "user",
    LinkComponent: NavLink,
    useExact: "true",
  },
  {
    value: "Feed",
    to: "/feed",
    icon: "rss",
    LinkComponent: NavLink,
    useExact: "true",
  },
  {
    value: "Toplists",
    to: "/top",
    icon: "award",
    LinkComponent: NavLink,
    useExact: "true",
  },
  {
    value: "Compare",
    to: "/compare",
    icon: "users",
    LinkComponent: NavLink,
    useExact: "true",
  },
  {
    value: "Daily",
    to: "/daily",
    icon: "calendar",
    LinkComponent: NavLink,
    useExact: "true",
  },
  {
    value: "Links",
    to: "/links",
    icon: "external-link",
    LinkComponent: NavLink,
    useExact: "true",
  },
  {
    value: "About",
    to: "/about",
    icon: "info",
    LinkComponent: NavLink,
    useExact: "true",
  },
];

type Props = {
  children?: React.ReactNode;
  hasData: boolean;
  onFetchClicked: () => void;
  setUsername: (username: string) => void;
  username: string;
};

const SiteWrapper: React.FC<Props> = ({
  children,
  hasData,
  onFetchClicked,
  setUsername,
  username,
}) => {
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme")
      ? localStorage.getItem("theme") === "dark"
      : prefersDarkMode
      ? true
      : false
  );

  const rootMatch = useMatch("/");
  const navigate = useNavigate();

  const handleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem("theme", !isDarkMode ? "dark" : "light");
  };

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (rootMatch) {
      const lastViewedUser = localStorage.getItem(LAST_VIEWED_PROFILE_KEY);
      if (lastViewedUser) {
        navigate(`/profile/${lastViewedUser}`);
      }
    }
  }, []);

  const renderSearchNavItem = () => {
    return (
      <Nav.Item key="search" type="div" className="d-none d-md-flex">
        <div className="header-fetch-wrapper">
          <input
            autoComplete="nickname"
            id="username-header"
            name="username"
            onChange={(e) => setUsername(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onFetchClicked();
            }}
            placeholder="Turf username ..."
            type="text"
            value={username}
          ></input>
          <button
            className="btn btn-secondary btn-sm ml-1 pt-1,5"
            onClick={onFetchClicked}
          >
            Go
          </button>
          <div className="ml-2 pointer" onClick={handleDarkMode}>
            <Icon name="sun" />
          </div>
        </div>
      </Nav.Item>
    );
  };

  const navItems = [
    <Nav.Item key="feed" type="div" className="d-none d-md-flex">
      <div className="header-new-wrapper">
        <Link to="/feed">
          <small>Feed</small>
        </Link>
      </div>
    </Nav.Item>,
  ];

  if (!rootMatch && hasData) {
    navItems.push(renderSearchNavItem());
  }

  return (
    <Site.Wrapper
      headerProps={{
        alt: "Turf Medals",
        href: "/",
        imageURL: `/brand_${isDarkMode ? "dark" : "light"}mode.svg`,
        navItems: navItems,
      }}
      navProps={{ itemsObjects: navBarItems }}
      footerProps={{
        copyright: (
          <>
            <DonateButton includeVipps small />
            Made with ❤️ by <a href="/profile/LordNorth">LordNorth</a>.<br />I
            have a family and create this stuff in my spare time.
            <br />
            If you want to keep me motivated, feel free to{" "}
            <a href="https://ko-fi.com/N4N1VWRUQ" target="_blank">
              send me a few bucks
            </a>
            .
          </>
        ),
      }}
    >
      {children}
      <SupportersFooter />
    </Site.Wrapper>
  );
};

export default SiteWrapper;
