import React, { Fragment } from "react";

type Props = {
  username: string;
  userid: number;
};

const ProfileLink: React.FC<{
  url: string;
  title: string;
  userIdentifier: string | number;
}> = ({ url, title, userIdentifier }) => (
  <a
    className="text-muted"
    href={`${url}${userIdentifier}`}
    rel="noreferrer"
    target="_blank"
    title={`View on ${title}`}
  >
    {title}
  </a>
);

const sites = [
  {
    title: "TurfGame",
    url: "https://turfgame.com/user/",
  },
  {
    title: "Turfportalen",
    url: "https://turfportalen.se/player/",
  },
  {
    title: "Frut",
    url: "https://frut.zundin.se/user_round.php?userid=",
  },
  {
    title: "TurfAOI",
    url: "https://turf-aoi.matb.se/?p=",
  },
  {
    useId: true,
    title: "TurfDB",
    url: "https://www.arbring.se/turf/user.php?id=",
  },
];

const ProfileLinks: React.FC<Props> = ({ userid, username }) => (
  <div className="h6 mt-2 text-muted">
    View on:{" "}
    {sites
      .map<React.ReactNode>((site) => (
        <Fragment key={site.url}>
          <ProfileLink
            title={site.title}
            url={site.url}
            userIdentifier={site.useId ? userid : username}
          />
        </Fragment>
      ))
      .reduce((prev, curr) => [prev, " | ", curr])}
  </div>
);

export default React.memo(ProfileLinks);
