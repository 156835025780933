import useTrackedMedals from "./useTrackedMedals";
import useUsername from "./useUsername";

function useSingleTrackedMedal(medalId: number) {
  const username = useUsername();
  const { addMedal, removeMedal, trackedMedals } = useTrackedMedals();

  const tracked = username && medalId && trackedMedals[username]?.[medalId];

  const toggleTracked = () => {
    if (tracked) {
      removeMedal(medalId);
    } else {
      addMedal(medalId);
    }
  };

  return { tracked, toggleTracked, username };
}

export default useSingleTrackedMedal;