import React, { useEffect } from "react";
import { Card } from "tabler-react";

import TopDaily from "../Components/Top/TopDaily";
import TopRip from "../Components/Top/TopRip";

const Top: React.FC = () => {
  useEffect(() => {
    document.title = `Daily - Turf Medals`;
  }, []);

  return (
    <div>
      <Card className="p-4">
        <TopDaily />
        <TopRip />
      </Card>
    </div>
  );
};

export default React.memo(Top);
