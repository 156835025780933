import React from "react";
import { Card, Grid, Loader } from "tabler-react";

import DonateButton from "../Components/Cards/DonateButton";
import GradedMedalsTable from "../Components/GradedMedalsTable";
import ObscureMedals from "../Components/ObscureMedals";
import OtherMedals from "../Components/OtherMedals";
import ProfileHeader from "../Components/ProfileHeader";
import UserCards from "../Components/UserCards";
import UserData from "../Types/UserData";
import { MedalDataWithAcquired } from "../Types/MedalTypes";
import EasyMedals from "../Components/EasyMedals";
import LatestZones from "../Components/LatestZones";

type Props = {
  allMedals: MedalDataWithAcquired[];
  loading: boolean;
  userData: UserData | null;
  userSingleMedals: MedalDataWithAcquired[];
  userMissingMedals: MedalDataWithAcquired[];
};

const Profile: React.FC<Props> = ({
  allMedals,
  loading,
  userData,
  userSingleMedals: userMedals,
  userMissingMedals,
}) => {
  const hasData = userData != null;

  if (loading) {
    return (
      <div className="user-loader">
        <Loader />
      </div>
    );
  }

  if (!hasData) {
    return null;
  }

  return (
    <>
      <ProfileHeader userData={userData} userMedals={userMedals} />

      <UserCards userData={userData} />

      <div className="text-center pt-2 pb-4">
        <DonateButton includeVipps small />
      </div>

      <Grid.Row>
        <Grid.Col lg={6}>
          <Card>
            <Card.Header>
              <Card.Title>Graded medals</Card.Title>
            </Card.Header>

            <GradedMedalsTable userData={userData} />
          </Card>
        </Grid.Col>
        <Grid.Col lg={6}>
          <OtherMedals
            allMedals={allMedals}
            userData={userData}
            userMedals={userMedals}
            userMissingMedals={userMissingMedals}
          />
          <ObscureMedals userData={userData} />
          <EasyMedals userData={userData} />
        </Grid.Col>
      </Grid.Row>
      {userData.region?.name && (
        <Grid.Row>
          <Grid.Col lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>
                  Latest created zones in {userData.region.name}
                </Card.Title>
              </Card.Header>
              <LatestZones userData={userData} />
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

export default React.memo(Profile);
