import React from "react";
import { Button } from "tabler-react";

type Props = {
  onFetchClicked: () => void;
  setUsername: (username: string) => void;
  username: string;
};

const Search: React.FC<Props> = ({ onFetchClicked, setUsername, username }) => (
  <div className="username-input-wrapper">
    <input
      autoComplete="nickname"
      id="username"
      name="username"
      onChange={(e) => setUsername(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") onFetchClicked();
      }}
      placeholder="Turf username ..."
      type="text"
      value={username}
    ></input>
    <Button className="btn btn-primary pl-4" onClick={onFetchClicked}>
      Fetch
    </Button>
  </div>
);

export default React.memo(Search);
