import React, { useState } from "react";
import { Card, Form, Grid } from "tabler-react";

import AnchorBadge from "../Components/AnchorBadge";
import MedalItem from "../Components/MedalItem";
import useMedalsData from "../Hooks/useMedalsData";
import { FilterRareMedals } from "../Lib/Medals";
import { MedalDataWithAcquired } from "../Types/MedalTypes";
import UserData from "../Types/UserData";

type Props = {
  allMedals: MedalDataWithAcquired[];
  userData: UserData;
  userMedals: MedalDataWithAcquired[];
  userMissingMedals: MedalDataWithAcquired[];
};

const OtherMedals: React.FC<Props> = ({
  allMedals,
  userData,
  userMedals,
  userMissingMedals,
}) => {
  const [attainable, setAttainable] = useState<boolean>(false);
  const [medalView, setMedalView] = useState<string>("all");
  const { turfMedalsData } = useMedalsData();

  const showMedals =
    medalView === "acquired"
      ? userMedals
      : medalView === "missing"
      ? userMissingMedals
      : allMedals;

  return (
    <Card>
      <Card.Header>
        <Grid.Row className="flex-grow">
          <Grid.Col width={5}>
            <Card.Title>Other medals</Card.Title>
          </Grid.Col>
          <Grid.Col className="text-right" width={7}>
            <AnchorBadge
              curView={medalView}
              forView="all"
              setView={setMedalView}
            />
            <AnchorBadge
              curView={medalView}
              forView="acquired"
              setView={setMedalView}
            />
            <AnchorBadge
              curView={medalView}
              forView="missing"
              setView={setMedalView}
            />
          </Grid.Col>
        </Grid.Row>
      </Card.Header>
      <Card.Body>
        <div>
          {Object.values(
            attainable
              ? FilterRareMedals(showMedals, userData?.country, turfMedalsData)
              : showMedals
          ).map((m) => (
            <MedalItem
              acquired={m.acquired}
              key={m.id}
              medal={m}
              userData={userData}
            />
          ))}
        </div>
        <div className="mt-2">
          <Form.Checkbox
            checked={attainable}
            label="Hide unavailable, from other countries, and those only attainable for fanatics."
            onChange={() => {
              setAttainable(!attainable);
            }}
            name="attainable"
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default React.memo(OtherMedals);
