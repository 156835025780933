import React, { useEffect, useState } from "react";
import { Card, Grid } from "tabler-react";

import MedalItem from "../Components/MedalItem";
import useMedalsData from "../Hooks/useMedalsData";
import UserData from "../Types/UserData";
import { MedalData } from "../Types/MedalTypes";
import { getNextMedal } from "../Utils";

const LIMIT = 6;

type Props = {
  userData: UserData;
};

const EasyMedals: React.FC<Props> = ({ userData }) => {
  const [sortedMedals, setSortedMedals] = useState<MedalData[]>([]);
  const { gradedMedals, singleMedals, turfMedalsData } = useMedalsData();

  useEffect(() => {
    if (gradedMedals && singleMedals && turfMedalsData) {
      const nextGradedMedals: MedalData[] = [];
      Object.values(gradedMedals).forEach((gm) => {
        const nextMedal = getNextMedal(gm, userData.medals);
        if (nextMedal) {
          nextGradedMedals.push(nextMedal);
        }
      });
      const allMedals = [...nextGradedMedals, ...Object.values(singleMedals)];

      const untakenMedals = Object.values(allMedals).filter(
        (m) => !userData.medals.includes(m.id)
      );
      setSortedMedals(
        untakenMedals
          .filter((m) => m.discontinued !== true)
          .sort((a, b) => {
            return (
              (turfMedalsData[b.id]?.totalHolders ?? 0) -
              (turfMedalsData[a.id]?.totalHolders ?? 0)
            );
          })
          .slice(0, LIMIT)
      );
    }
  }, [gradedMedals, singleMedals, turfMedalsData, userData]);

  return (
    <Card>
      <Card.Header>
        <Grid.Row className="flex-grow">
          <Grid.Col>
            <Card.Title>
              Top {LIMIT} easiest<sup>*</sup> medals yet to claim
            </Card.Title>
          </Grid.Col>
        </Grid.Row>
      </Card.Header>
      <Card.Body>
        <div>
          {Object.values(sortedMedals).map((m) => (
            <MedalItem key={m.id} medal={m} userData={userData} />
          ))}
        </div>
        <div className="mt-2 text-muted">
          <sup>*</sup> Based on the number of total holders for each medal.
        </div>
      </Card.Body>
    </Card>
  );
};

export default React.memo(EasyMedals);
