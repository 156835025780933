import React, { useEffect, useState } from "react";
import { Button, Table } from "tabler-react";
import { onValue, limitToLast, orderByChild, query } from "firebase/database";

import { turfersRef } from "../../Services/FirebaseDB";
import { useSupporters } from "../../Hooks/useSupporters";
import { FirebaseUserData } from "../../Types/UserData";
import Username from "../Username";

const LIMIT_STEP = 38;
const MAX_LIMIT = 150;

type TurfersMap = Record<string, FirebaseUserData>;

const TopDaily: React.FC = () => {
  const [turfers, setTurfers] = useState<FirebaseUserData[]>([]);
  const [topLimit, setTopLimit] = useState(LIMIT_STEP);
  const supporters = useSupporters();

  const showMore = () => {
    setTopLimit((prevLimit) => prevLimit + LIMIT_STEP); // Increase limit by 20 (or any other number)
  };

  useEffect(() => {
    const turfersTopRef = query(
      turfersRef,
      orderByChild("daily/current"),
      limitToLast(topLimit)
    );

    const unsub = onValue(turfersTopRef, (snapshot) => {
      const data: TurfersMap = snapshot.val();
      if (!data) {
        setTurfers([]);
        return;
      }
      const dataGetter = (u: FirebaseUserData) => u.daily?.current || 0;

      const sortedList = Object.values(data).sort((a, b) =>
        dataGetter(a) < dataGetter(b) ? 1 : -1
      );
      setTurfers(Object.values(sortedList));
    });

    return () => unsub();
  }, [topLimit]);

  let postitionCounter = 0;
  let latestCurrent = -1;

  return (
    <div>
      <h3>Top Daily</h3>

      <p>
        Source:{" "}
        <a href="https://turfbridge.com/rip.php">Turfbridge.com/rip.php</a>
      </p>

      <Table
        cards={true}
        className="table-vcenter"
        responsive={true}
        striped={true}
      >
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>#</Table.ColHeader>
            <Table.ColHeader>Name</Table.ColHeader>
            <Table.ColHeader>Current</Table.ColHeader>
            <Table.ColHeader>Record</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {turfers.map((t, index) => {
            if (latestCurrent !== t.daily?.current) {
              postitionCounter = index + 1;
              latestCurrent = t.daily!.current;
            }
            return (
              <Table.Row key={t.name}>
                <Table.Col>{postitionCounter}</Table.Col>
                <Table.Col>
                  <Username username={t.name} supporters={supporters} />
                </Table.Col>
                <Table.Col>
                  <strong>{t.daily?.current || "-"}</strong>
                </Table.Col>
                <Table.Col>{t.daily?.record || "-"}</Table.Col>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {topLimit < MAX_LIMIT && (
        <div className="m-4">
          <Button onClick={showMore}>Show more</Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(TopDaily);
