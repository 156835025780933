import React, { useEffect, useState } from "react";
import { Card, Grid } from "tabler-react";

import MedalItem from "../Components/MedalItem";
import useMedalsData from "../Hooks/useMedalsData";
import UserData from "../Types/UserData";
import { GetAllMedals } from "../Lib/Medals";

const ALL_MEDALS = GetAllMedals();

const LIMIT = 6;

type Props = {
  userData: UserData;
};

const ObscureMedals: React.FC<Props> = ({ userData }) => {
  const [sortedMedals, setSortedMedals] = useState<number[]>([]);
  const { turfMedalsData } = useMedalsData();

  useEffect(() => {
    if (turfMedalsData != null) {
      setSortedMedals(
        [...new Set(userData.medals)]
          .sort((a, b) => {
            return (
              turfMedalsData[a].totalHolders! - turfMedalsData[b].totalHolders!
            );
          })
          .slice(0, LIMIT)
      );
    }
  }, [turfMedalsData, userData]);

  return (
    <Card>
      <Card.Header>
        <Grid.Row className="flex-grow">
          <Grid.Col>
            <Card.Title>
              Top {LIMIT} most obscure<sup>*</sup> medals
            </Card.Title>
          </Grid.Col>
        </Grid.Row>
      </Card.Header>
      <Card.Body>
        <div>
          {Object.values(sortedMedals).map((m) => (
            <MedalItem
              acquired
              key={m}
              medal={ALL_MEDALS[m]}
              userData={userData}
            />
          ))}
        </div>
        <div className="mt-2 text-muted">
          <sup>*</sup> Based on the number of total holders for each medal.
        </div>
      </Card.Body>
    </Card>
  );
};

export default React.memo(ObscureMedals);
