import React from "react";
import { MedalGroup } from "../Types/MedalTypes";
import { getNextMedal, getCurrentMedal } from "../Utils";
import MedalItem from "./MedalItem";
import UserData from "../Types/UserData";

type MedalGroupItemProps = {
  next?: boolean;
  medalGroup: MedalGroup;
  userData: UserData;
};

const MedalGroupItem: React.FC<MedalGroupItemProps> = (props) => {
  const { medalGroup, next = false, userData } = props;

  const medal = next
    ? getNextMedal(medalGroup, userData.medals)
    : getCurrentMedal(medalGroup, userData.medals);

  if (!medal) return null;

  return (
    <MedalItem
      acquired={!next}
      medal={medal}
      medalGroup={medalGroup}
      name={medal.name || `${medalGroup.name}-${medal.value}`}
      userData={userData}
    />
  );
};

export default React.memo(MedalGroupItem);
