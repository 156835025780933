import React, { Fragment, useEffect, useState } from "react";
import { Button, Table } from "tabler-react";
import { onValue, limitToLast, query, orderByKey } from "firebase/database";

import { dailyRef } from "../../Services/FirebaseDB";
import { useSupporters } from "../../Hooks/useSupporters";
import Username from "../Username";

const LIMIT_STEP = 20;
const MAX_LIMIT = 100;

type DailyEntry = {
  active: number;
  lost: string[];
};
type DailyMap = Record<string, DailyEntry>;

const TopRip: React.FC = () => {
  const [lostStreaks, setLostStreaks] = useState<[string, DailyEntry][]>([]);
  const [limit, setLimit] = useState(LIMIT_STEP);
  const supporters = useSupporters();

  const showMore = () => {
    setLimit((prevLimit) => prevLimit + LIMIT_STEP); // Increase limit by 20 (or any other number)
  };

  useEffect(() => {
    const lostTopRef = query(dailyRef, orderByKey(), limitToLast(limit));

    const unsub = onValue(lostTopRef, (snapshot) => {
      const data: DailyMap = snapshot?.val();
      if (!data) {
        setLostStreaks([]);
        return;
      }
      const sortedData = Object.entries(data).sort(
        ([keyA], [keyB]) => parseInt(keyB) - parseInt(keyA)
      );
      setLostStreaks(sortedData);
    });

    return () => unsub();
  }, [limit]);

  return (
    <div className="mt-8">
      <h3 id="RIP">R.I.P.: Lost Daily Streaks</h3>
      <Table
        cards={true}
        striped={true}
        responsive={true}
        className="table-vcenter"
      >
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Days</Table.ColHeader>
            <Table.ColHeader>Turfers</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lostStreaks.map(([key, entry]) => (
            <Table.Row key={key}>
              <Table.Col>{key}</Table.Col>
              <Table.Col>
                {entry.lost.map((name, index) => (
                  <Fragment key={name}>
                    <Username
                      key={name}
                      supporters={supporters}
                      username={name}
                    />
                    {index !== entry.lost.length - 1 && " | "}
                  </Fragment>
                ))}
              </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {limit < MAX_LIMIT && (
        <div className="m-4">
          <Button onClick={showMore}>Show more</Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(TopRip);
