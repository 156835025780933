import {
  MedalData,
  MedalDataWithAcquired,
  MedalDataWithGroupData,
  MedalDataWithTurfData,
  MedalGroup,
  MedalGroupWithTurfData,
  TurfMedalsDto,
} from "../Types/MedalTypes";
import { TakeType } from "../Types/TakeTypes";

export type GradedMedalsType = Record<string, MedalGroup>;
export type SingleMedalsType = Record<string, MedalData>;

export const COMMON_MEDALS_HOLDERS_LIMIT = 1000; // Also update node red if changed

// #### IF UPDATING HERE, ALSO UPDATE NODE RED!!! Three nodes have this code ####

export const GradedMedals = {
  Take: {
    includeInCompare: true,
    name: "Take",
    medals: [
      {
        description:
          "This medal is distributed as you perform 5 takeovers in total.",
        id: 84,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "5",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-5",
      },
      {
        description:
          "This medal is distributed as you perform 10 takeovers in total.",
        id: 85,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "10",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-10",
      },
      {
        description:
          "This medal is distributed as you perform 25 takeovers in total.",
        id: 31,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "25",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-25",
      },
      {
        description:
          "This medal is distributed as you perform 50 takeovers in total.",
        id: 86,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "50",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-50",
      },
      {
        description:
          "This medal is distributed as you perform 100 takeovers in total.",
        id: 32,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "100",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-100",
      },
      {
        description:
          "This medal is distributed as you perform 250 takeovers in total.",
        id: 42,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "250",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-250",
      },
      {
        description:
          "This medal is distributed as you perform 500 takeovers in total.",
        id: 33,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-500",
      },
      {
        description:
          "This medal is distributed as you perform 1000 takeovers in total.",
        id: 34,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "1000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-1000",
      },
      {
        description:
          "This medal is distributed as you perform 2500 takeovers in total.",
        id: 35,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "2500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-2500",
      },
      {
        description:
          "This medal is distributed as you perform 5000 takeovers in total.",
        id: 36,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "5000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-5000",
      },
      {
        description:
          "This medal is distributed as you perform 7000 takeovers in total.",
        id: 37,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "7500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-7500",
      },
      {
        description:
          "This medal is distributed as you perform 10,000 takeovers in total.",
        id: 38,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "10000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-10000",
      },
      {
        description:
          "This medal is distributed as you perform 15,000 takeovers in total.",
        id: 39,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "15000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-15000",
      },
      {
        description:
          "This medal is distributed as you perform 20,000 takeovers in total.",
        id: 40,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "20000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-20000",
      },
      {
        description:
          "This medal is distributed as you perform 25,000 takeovers in total.",
        id: 41,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "25000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-25000",
      },
      {
        description:
          "This medal is distributed as you perform 30,000 takeovers in total.",
        id: 73,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "30000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-30000",
      },
      {
        description:
          "This medal is distributed as you perform 35,000 takeovers in total.",
        id: 87,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "35000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-35000",
      },
      {
        description:
          "This medal is distributed as you perform 40,000 takeovers in total.",
        id: 88,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "40000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-40000",
      },
      {
        description:
          "This medal is distributed as you perform 50,000 takeovers in total.",
        id: 89,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "50000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-50000",
      },
      {
        description:
          "This medal is distributed as you perform 75,000 takeovers in total.",
        id: 118,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "75000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-75000",
      },
      {
        description:
          "This medal is distributed as you perform 100,000 takeovers in total.",
        id: 119,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "100000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-100000",
      },
      {
        description:
          "This medal is distributed as you perform 250,000 takeovers in total.",
        id: 149,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "250000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-250000",
      },
      {
        description:
          "This medal is distributed as you perform 500,000 takeovers in total.",
        id: 150,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "500000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Take-500000",
      },
    ],
  },
  Unique: {
    includeInCompare: true,
    name: "Unique",
    medals: [
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 15 different zones.",
        id: 92,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "15",
        wikiLink: "https://wiki.turfgame.com/en/wiki/15_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 50 different zones.",
        id: 11,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "50",
        wikiLink: "https://wiki.turfgame.com/en/wiki/50_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 100 different zones.",
        id: 12,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "100",
        wikiLink: "https://wiki.turfgame.com/en/wiki/100_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 250 different zones.",
        id: 13,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "250",
        wikiLink: "https://wiki.turfgame.com/en/wiki/250_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 500 different zones.",
        id: 14,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/500_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 1,000 different zones.",
        id: 15,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "1000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/1000_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 2,500 different zones.",
        id: 50,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "2500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/2500_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 5,000 different zones.",
        id: 74,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "5000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/5000_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 7,500 different zones.",
        id: 120,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "7500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/7500_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 10,000 different zones.",
        id: 121,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "10000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/10000_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 20,000 different zones.",
        id: 140,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "20000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/20000_Unique",
      },
      {
        description:
          "This medal is worn by the turf-fellow who grabbed at least 30,000 different zones.",
        id: 141,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Warded.se has a map with <a href='https://warded.se/turf/untaken.php'>all of your untaken zones</a> (must be logged in). Login, go to your profile and click the 'Map over my never taken zones' link.",
        value: "30000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/30000_Unique",
      },
    ],
  },
  Greed: {
    includeCurrentDataWhenCompleted: false,
    includeInCompare: true,
    name: "Greed",
    medals: [
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 5 zones at the same time.",
        id: 80,
        takeTypes: [TakeType.Takeover],
        value: "5",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-5",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 10 zones at the same time.",
        id: 51,
        takeTypes: [TakeType.Takeover],
        value: "10",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-10",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 20 zones at the same time.",
        id: 52,
        takeTypes: [TakeType.Takeover],
        value: "20",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-20",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 30 zones at the same time.",
        id: 53,
        takeTypes: [TakeType.Takeover],
        value: "30",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-30",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 40 zones at the same time.",
        id: 54,
        takeTypes: [TakeType.Takeover],
        value: "40",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-40",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 50 zones at the same time.",
        id: 55,
        takeTypes: [TakeType.Takeover],
        value: "50",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-50",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 75 zones at the same time.",
        id: 56,
        takeTypes: [TakeType.Takeover],
        value: "75",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-75",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 100 zones at the same time.",
        id: 57,
        takeTypes: [TakeType.Takeover],
        value: "100",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-100",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 150 zones at the same time.",
        id: 58,
        takeTypes: [TakeType.Takeover],
        value: "150",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-150",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 200 zones at the same time.",
        id: 59,
        takeTypes: [TakeType.Takeover],
        value: "200",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-200",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 250 zones at the same time.",
        id: 60,
        takeTypes: [TakeType.Takeover],
        value: "250",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-250",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 300 zones at the same time.",
        id: 81,
        takeTypes: [TakeType.Takeover],
        value: "300",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-300",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 350 zones at the same time.",
        id: 82,
        takeTypes: [TakeType.Takeover],
        value: "350",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-350",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 400 zones at the same time.",
        id: 146,
        takeTypes: [TakeType.Takeover],
        value: "400",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-400",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 500 zones at the same time.",
        id: 147,
        takeTypes: [TakeType.Takeover],
        value: "500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-500",
      },
      {
        description:
          "This medal is worn by the greedy turfer who controls at least 1000 zones at the same time.",
        id: 148,
        takeTypes: [TakeType.Takeover],
        value: "1000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Greed-1000",
      },
    ],
  },
  Assist: {
    includeInCompare: true,
    name: "Assist",
    medals: [
      {
        description:
          "The social Turfer who has assisted another Turfer five times with a zone takeover.",
        descriptionShort: "5 assists",
        id: 100,
        takeTypes: [TakeType.Assist],
        value: "5",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-5",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 25 times with a zone takeover.",
        descriptionShort: "25 assists",
        id: 101,
        takeTypes: [TakeType.Assist],
        value: "25",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-25",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 50 times with a zone takeover.",
        descriptionShort: "50 assists",
        id: 102,
        takeTypes: [TakeType.Assist],
        value: "50",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-50",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 100 times with a zone takeover.",
        descriptionShort: "100 assists",
        id: 103,
        takeTypes: [TakeType.Assist],
        value: "100",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-100",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 250 times with a zone takeover.",
        descriptionShort: "250 assists",
        id: 104,
        takeTypes: [TakeType.Assist],
        value: "250",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-250",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 500 times with a zone takeover.",
        descriptionShort: "500 assists",
        id: 105,
        takeTypes: [TakeType.Assist],
        value: "500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-500",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 750 times with a zone takeover.",
        descriptionShort: "750 assists",
        id: 106,
        takeTypes: [TakeType.Assist],
        value: "750",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-750",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 1,000 times with a zone takeover.",
        descriptionShort: "1k assists",
        id: 107,
        takeTypes: [TakeType.Assist],
        value: "1000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-1000",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 2,500 times with a zone takeover.",
        descriptionShort: "2.5k assists",
        id: 108,
        takeTypes: [TakeType.Assist],
        value: "2500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-2500",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 5,000 times with a zone takeover.",
        descriptionShort: "5k assists",
        id: 109,
        takeTypes: [TakeType.Assist],
        value: "5000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-5000",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 7,500 times with a zone takeover.",
        descriptionShort: "7.5k assists",
        id: 110,
        takeTypes: [TakeType.Assist],
        value: "7500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-7500",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 10,000 times with a zone takeover.",
        descriptionShort: "10k assists",
        id: 111,
        takeTypes: [TakeType.Assist],
        value: "10000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-10000",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 15,000 times with a zone takeover.",
        descriptionShort: "15k assists",
        id: 143,
        takeTypes: [TakeType.Assist],
        value: "15000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-15000",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 20,000 times with a zone takeover.",
        descriptionShort: "20k assists",
        id: 144,
        takeTypes: [TakeType.Assist],
        value: "20000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-20000",
      },
      {
        description:
          "The social Turfer who has assisted another Turfer 25,000 times with a zone takeover.",
        descriptionShort: "25k assists",
        id: 145,
        takeTypes: [TakeType.Assist],
        value: "25000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Assist-25000",
      },
    ],
  },
  Crowdy: {
    includeInCompare: true,
    name: "Crowdy",
    medals: [
      {
        description: "The social Turfer that have assist with a zone takeover.",
        descriptionShort: "1 assist",
        id: 93,
        takeTypes: [TakeType.Assist],
        value: "1",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-1",
      },
      {
        description:
          "The social Turfer who have been one of two, to assist with a zone takeover.",
        descriptionShort: "1/2 to assist",
        id: 94,
        takeTypes: [TakeType.Assist],
        value: "2",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-2",
      },
      {
        description:
          "The social Turfer who have been one of three, to assist with a zone takeover.",
        descriptionShort: "1/3 to assist",
        id: 95,
        takeTypes: [TakeType.Assist],
        value: "3",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-3",
      },
      {
        description:
          "The social Turfer who have been one of five, to assist with a zone takeover.",
        descriptionShort: "1/5 to assist",
        id: 96,
        takeTypes: [TakeType.Assist],
        value: "5",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-5",
      },
      {
        description:
          "The social Turfer who have been one of eight, to assist with a zone takeover.",
        descriptionShort: "1/8 to assist",
        id: 97,
        takeTypes: [TakeType.Assist],
        value: "8",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-8",
      },
      {
        description:
          "The social Turfer who have been one of thirteen, to assist with a zone takeover.",
        descriptionShort: "1/13 to assist",
        id: 98,
        takeTypes: [TakeType.Assist],
        value: "13",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-13",
      },
      {
        description:
          "The social Turfer who have been one of twenty one, to assist with a zone takeover.",
        descriptionShort: "1/20 to assist",
        id: 99,
        takeTypes: [TakeType.Assist],
        value: "21",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-21",
      },
      {
        description:
          "The social Turfer who have been one of thirty four, to assist with a zone takeover.",
        descriptionShort: "1/30 to assist",
        id: 112,
        takeTypes: [TakeType.Assist],
        value: "34",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-34",
      },
      {
        description:
          "The social Turfer who have been one of fifty five, to assist with a zone takeover.",
        descriptionShort: "1/55 to assist",
        id: 114,
        takeTypes: [TakeType.Assist],
        value: "55",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-55",
      },
      {
        description:
          "The social Turfer who have been one of eighty nine, to assist with a zone takeover.",
        descriptionShort: "1/88 to assist",
        id: 115,
        takeTypes: [TakeType.Assist],
        value: "89",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-89",
      },
      {
        description:
          "The social Turfer who have been one of one hundred and forty four, to assist with a zone takeover.",
        descriptionShort: "1/144 to assist",
        id: 116,
        takeTypes: [TakeType.Assist],
        value: "144",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-144",
      },
      {
        description:
          "The social Turfer who have been one of one 233, to assist with a zone takeover.",
        descriptionShort: "1/233 to assist",
        id: 142,
        takeTypes: [TakeType.Assist],
        value: "233",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Crowdy-233",
      },
    ],
  },
  Roundpointer: {
    includeInCompare: true,
    name: "Roundpointer",
    medals: [
      {
        description:
          "This medal is awarded when taking 10.000 points in a single round.",
        id: 151,
        name: "Roundpointer-10k",
        value: "10000",
      },
      {
        description:
          "This medal is awarded when taking 25.000 points in a single round.",
        id: 152,
        name: "Roundpointer-25k",
        value: "25000",
      },
      {
        description:
          "This medal is awarded when taking 50.000 points in a single round.",
        id: 153,
        name: "Roundpointer-50k",
        value: "50000",
      },
      {
        description:
          "This medal is awarded when taking 75.000 points in a single round.",
        id: 154,
        name: "Roundpointer-75k",
        value: "75000",
      },
      {
        description:
          "This medal is awarded when taking 100.000 points in a single round.",
        id: 155,
        name: "Roundpointer-100k",
        value: "100000",
      },
      {
        description:
          "This medal is awarded when taking 250.000 points in a single round.",
        id: 156,
        name: "Roundpointer-250k",
        value: "250000",
      },
      {
        description:
          "This medal is awarded when taking 500.000 points in a single round.",
        id: 157,
        name: "Roundpointer-500k",
        value: "500000",
      },
      {
        description:
          "This medal is awarded when taking 750.000 points in a single round.",
        id: 158,
        name: "Roundpointer-750k",
        value: "750000",
      },
      {
        description:
          "This medal is awarded when taking 1.000.000 points in a single round.",
        id: 159,
        name: "Roundpointer-1M",
        value: "1000000",
      },
      {
        description:
          "This medal is awarded when taking 2.000.000 points in a single round.",
        id: 160,
        name: "Roundpointer-2M",
        value: "2000000",
      },
      {
        description:
          "This medal is awarded when taking 3.000.000 points in a single round.",
        id: 161,
        name: "Roundpointer-3M",
        value: "3000000",
      },
    ],
  },
  Diverse: {
    includeCurrentDataWhenCompleted: false,
    includeInCompare: true,
    name: "Diverse",
    medals: [
      {
        description:
          "The active turfer who have taken 250 round unique zones during the same round.",
        id: 122,
        name: "Diverse (250)",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "250",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Diverse",
      },
      {
        description:
          "The active turfer who have taken 500 round unique zones during the same round. Can only be taken once and it replaces the Diverse medal.",
        id: 123,
        name: "Diversier (500)",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "500",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Diversier",
      },
      {
        description:
          "The active turfer who have taken 1 000 round unique zones during the same round. Can only be taken once and it replaces the Diversier medal.",
        id: 124,
        name: "Diversiest (1000)",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "1000",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Diversiest",
      },
    ],
  },
  RegionStar: {
    includeInCompare: true,
    name: "Region Star",
    medals: [
      {
        description:
          "This medal is awarded to the travelling kind who have taken zones in at least five regions.",
        id: 6,
        name: "Pentaregion Star",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "5",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Pentaregion_Star",
      },
      {
        description:
          "This medal is awarded to the very traveling kind who have taken zones in at least ten regions. This medal can be achieved only once and replaces the Pentaregion Star medal.",
        id: 7,
        name: "Decaregion Star",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "10",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Decaregion_Star",
      },
      {
        description:
          "This medal is awarded to the most traveling kind who have taken zones in at least 20 regions. This medal can be achieved only once and replaces the Decaregion Star medal.",
        id: 66,
        name: "Icosaregion Star",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "20",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Icosaregion_Star",
      },
      {
        description:
          "This medal is awarded to the most traveling kind who have taken zones in at least 30 regions. This medal can be achieved only once and replaces the Icosaregion Star medal.",
        id: 91,
        name: "Tricontaregion Star",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "30",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Tricontaregion_star",
      },
    ],
  },
  Daily: {
    includeInCompare: true,
    name: "Daily",
    medals: [
      {
        description:
          "This medal is worn by the turfers who have taken 5 zones every day for 10 days straight. Can only be taken once.",
        descriptionShort: "5z 10 days",
        id: 127,
        takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
        value: "10",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Daily-10",
      },
      {
        description:
          "This medal is worn by the turfers who have taken 5 zones every day for 30 days straight. Can only be taken once and replaces the Daily-10 medal.",
        descriptionShort: "5z 30 days",
        id: 128,
        takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
        value: "30",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Daily-30",
      },
      {
        description:
          "This medal is worn by the turfers who have taken 5 zones every day for 60 days straight. Can only be taken once and replaces the Daily-30 medal.",
        descriptionShort: "5z 60 days",
        id: 129,
        takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
        value: "60",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Daily-60",
      },
      {
        description:
          "This medal is worn by the turfers who have taken 5 zones every day for 90 days straight. Can only be taken once and replaces the Daily-60 medal.",
        descriptionShort: "5z 90 days",
        id: 130,
        takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
        value: "90",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Daily-90",
      },
      {
        description:
          "This medal is worn by the turfers who have taken 5 zones every day for 180 days straight. Can only be taken once and replaces the Daily-90 medal.",
        descriptionShort: "5z 180 days",
        id: 131,
        takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
        value: "180",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Daily-180",
      },
      {
        description:
          "This medal is worn by the turfers who have taken 5 zones every day for 365 days straight. Can only be taken once and replaces the Daily-180 medal.",
        descriptionShort: "5z 365 days",
        id: 132,
        takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
        value: "365",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Daily-365",
      },
    ],
  },
  Eager: {
    includeInCompare: true,
    name: "Eager",
    medals: [
      {
        description:
          "This medal is worn by those who took at least 10 zones within 30 minutes. PS: It is not possible to combine takeovers and assists.",
        descriptionShort: "10z in 30m",
        id: 90,
        name: "Eager Squirrel",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "30",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Eager_Squirrel",
      },
      {
        description:
          "This medal is worn by those who took at least 10 zones within 20 minutes. PS: It is not possible to combine takeovers and assists.",
        descriptionShort: "10z in 20m",
        id: 10,
        name: "Eager Beaver",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "20",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Eager_Beaver",
      },
      {
        description:
          "This medal is worn by those who took at least 10 zones within 15 minutes. PS: It is not possible to combine takeovers and assists.",
        descriptionShort: "10z in 15m",
        id: 28,
        name: "Eager Weasel",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "If you can find 5 very close zones, it might help to attempt this medal just when a round restarts. Take the 5 zones just before the round restart, and then retake them in the new round.",
        value: "15",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Eager_Weasel",
      },
      {
        description:
          "This medal is worn by those who took at least 10 zones within 10 minutes. PS: It is not possible to combine takeovers and assists.",
        descriptionShort: "10z in 10m",
        id: 72,
        name: "Eager Ferret",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "If you can find 5 very close zones, it might help to attempt this medal just when a round restarts. Take the 5 zones just before the round restart, and then retake them in the new round.",
        value: "10",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Eager_Ferret",
      },
    ],
  },
  Stamina: {
    includeInCompare: true,
    name: "Stamina",
    medals: [
      {
        description:
          "This medal is worn by one of the awesome Turfers who took at least 50 zones over a 24 hour period.",
        descriptionShort: "50z in 24h",
        id: 46,
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "50",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Stamina",
      },
      {
        description:
          "This medal is worn by one of the fantastic Turfers who took at least 100 zones over a 24 hour period.",
        descriptionShort: "100z in 24h",
        id: 47,
        name: "Staminator (100)",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "100",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Staminator",
      },
      {
        description:
          "This medal is worn by one of the incredible Turfers who took at least 200 zones over a 24 hour period.",
        descriptionShort: "200z in 24h",
        id: 48,
        name: "El Staminatore (200)",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "200",
        wikiLink: "https://wiki.turfgame.com/en/wiki/El_Staminatore",
      },
      {
        description:
          "Belongs to those insane Turfers who take at least 400 zones over a 24 hour period.",
        descriptionShort: "400z in 24h",
        id: 75,
        name: "Staminatrix (400)",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        value: "400",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Staminatrix",
      },
    ],
  },
  Restless: {
    includeInCompare: true,
    name: "Restless",
    medals: [
      {
        description:
          "This medal is worn by the unbelievable Turfer which takes at least a zone every hour over a 12 hour period. This medal can be achieved only once.",
        descriptionShort: "1z every h",
        id: 22,
        name: "Restless",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Take zones just before and right after the whole hour, that way you only have to go out roughly every 2 hours for the 12 hour period.",
        value: "12",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Restless",
      },
      {
        description:
          "This medal is worn by the unbelievable Turfer which takes at least a zone every hour over a 24 hour period. This medal can be achieved only once and replaces the Restless medal.",
        descriptionShort: "1z every h",
        id: 21,
        name: "Insomnia",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "Take zones just before and right after every other whole hour, that way you only have to go out every 2 hours for the 24 hour period.",
        value: "24",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Insomnia",
      },
    ],
  },
  DarkNinja: {
    includeInCompare: true,
    name: "Dark Ninja",
    medals: [
      {
        description:
          "A medal awarded to the daring ones who have taken at least three zones between the hours 02:00 AM and 03:00 AM. Revisits do not count.",
        descriptionShort: "3z 02-03 AM",
        id: 5,
        name: "Dark Ninja",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        wikiLink: "https://wiki.turfgame.com/en/wiki/Dark_Ninja",
      },
      {
        description:
          "A medal awarded to the daring ones who have taken at least 30 zones between the hours 02:00 AM and 03:00 AM. Revisits do not count.",
        descriptionShort: "30z 02-03 AM",
        id: 83,
        name: "Darkest Ninja",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        wikiLink: "https://wiki.turfgame.com/en/wiki/Darkest_Ninja",
      },
    ],
  },
  BridgeCrosser: {
    includeInCompare: true,
    name: "Bridge",
    medals: [
      {
        description:
          "This medal is worn by the turfers who have taken at least 3 bridge zones in 30 minutes.",
        descriptionShort: "3 bridges 30m",
        id: 71,
        name: "Bridge crosser",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        tip: "If you can find two nearby bridges, a way to achieve this medal is to take the two bridges just minutes before a round restart, and then take the second one again right after.",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Bridge_crosser",
      },
      {
        description:
          "This medal is worn by the turfers who have taken at least 3 bridge zones in 20 minutes.",
        descriptionShort: "3 bridges 20m",
        id: 78,
        name: "Bridge lover",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        wikiLink: "https://wiki.turfgame.com/en/wiki/Bridge_lover",
      },
      {
        description:
          "This medal is worn by the turfers who have taken at least 3 bridge zones in 10 minutes.",
        descriptionShort: "3 bridges 10m",
        id: 79,
        name: "Bridge fanatic",
        takeTypes: [TakeType.Takeover, TakeType.Assist],
        wikiLink: "https://wiki.turfgame.com/en/wiki/Bridge_fanatic",
      },
    ],
  },
  Monument: {
    includeInCompare: true,
    name: "Monument",
    medals: [
      {
        description:
          "This medal is awarded after taking 50 unique monument zones.",
        descriptionShort: "50 monuments",
        id: 162,
        name: "Monument",
        tip: 'To view all Monument zones, use <a href="https://turf.lundkvist.com/en/?country=SP21&countrySP=%23ALLA%23">Lundkvist</a> or the <a href="https://turf.urbangeeks.org/">Turf Map Tool</a> (use "Tag filter").',
        value: "50",
      },
      {
        description:
          "This medal is awarded after taking 100 unique monument zones.",
        descriptionShort: "100 monuments",
        id: 163,
        name: "Monumental",
        tip: 'To view all Monument zones, use <a href="https://turf.lundkvist.com/en/?country=SP21&countrySP=%23ALLA%23">Lundkvist</a> or the <a href="https://turf.urbangeeks.org/">Turf Map Tool</a> (use "Tag filter").',
        value: "100",
      },
      {
        description:
          "This medal is awarded after taking 250 unique monument zones.",

        descriptionShort: "250 monuments",
        id: 164,
        name: "Monumentalist",
        tip: 'To view all Monument zones, use <a href="https://turf.lundkvist.com/en/?country=SP21&countrySP=%23ALLA%23">Lundkvist</a> or the <a href="https://turf.urbangeeks.org/">Turf Map Tool</a> (use "Tag filter").',
        value: "250",
      },
      {
        description:
          "This medal is awarded after taking 500 unique monument zones.",
        descriptionShort: "500 monuments",
        id: 165,
        name: "Mondo",
        tip: 'To view all Monument zones, use <a href="https://turf.lundkvist.com/en/?country=SP21&countrySP=%23ALLA%23">Lundkvist</a> or the <a href="https://turf.urbangeeks.org/">Turf Map Tool</a> (use "Tag filter").',
        value: "500",
      },
    ],
  },
  Donation: {
    discontinued: true,
    name: "Donation",
    medals: [
      {
        description:
          "Was awarded the brave soul who donates at least 10€ to the Turf project. It could only be taken once. The medal stopped being awarded during 2015 and can thus no longer be obtained.",
        discontinued: true,
        id: 9,
        name: "Donation Hero",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Supporter",
      },
      {
        description:
          "Was awarded the brave soul who donates at least 50€ to the Turf project. It could only be taken once and replaced Donation Hero. The medal stopped being awarded during 2015 and can thus no longer be obtained.",
        discontinued: true,
        id: 44,
        name: "Donation Angel",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Supporter",
      },
      {
        description:
          "Was awarded the brave soul who donates at least 100€ to the Turf project. It could only be taken once and replaced Donation Angel. The medal stopped being awarded during 2015 and can thus no longer be obtained.",
        discontinued: true,
        id: 43,
        name: "Donation God",
        wikiLink: "https://wiki.turfgame.com/en/wiki/Supporter",
      },
    ],
  },
};

export const SingleMedals: SingleMedalsType = {
  GoldMedal: {
    description:
      "This medal is awarded to those who have won a round of Turf. A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 1,
    name: "Gold Medal",
    unattainable: true,
    wikiLink: "https://wiki.turfgame.com/en/wiki/Gold_medal",
  },
  SilverMedal: {
    description:
      "This medal is awarded to those who finished second in a round of Turf. A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 2,
    name: "Silver Medal",
    unattainable: true,
    wikiLink: "https://wiki.turfgame.com/en/wiki/Silver_medal",
  },
  BronzeMedal: {
    description:
      "This medal is awarded to those who finished in third place in a round of Turf. A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 3,
    name: "Bronze Medal",
    unattainable: true,
    wikiLink: "https://wiki.turfgame.com/en/wiki/Bronze_medal",
  },
  CountryMedal: {
    description:
      "This medal is awarded to those who end up as round winner in a country that is a official turf country. (30 turfers in country required). A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 69,
    name: "Country Medal",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Country_medal",
  },
  RegionMedal: {
    description:
      "This medal is awarded to those who end up as round winner in a region. (5 turfers in region required and the region must be in one of the official turf countries). A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 68,
    name: "Region Medal",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Region_medal",
  },
  Expandator: {
    description:
      "The Expandator medal belongs to the honorful ones who have helped spreading the word of Turf by putting up at least 25 Turf posters.",
    id: 8,
    name: "Expandator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Expandator",
  },
  WebExpandator: {
    description:
      "The Web Expandator medal belongs to those who make Turf appear on a big blog or webpage. (more than 1000 hits/day)",
    id: 61,
    name: "Web Expandator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Web_Expandator",
  },
  BroadcastExpandator: {
    description:
      "The Broadcast Expandator medal belongs to those who make Turf appear in radio or tv. (audience more than 1000)",
    id: 62,
    name: "Broadcast Expandator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Broadcast_Expandator",
  },
  WikiExpandator: {
    description:
      "The Wiki Expandator medal belongs to those who make 400 relevant contributions to the Turf wiki.",
    id: 63,
    name: "Wiki Expandator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Wiki_Expandator",
  },
  PaperExpandator: {
    description:
      "The Paper Expandator medal belongs to those who make Turf appear in a newspaper. (more than 1000 readers/issue)",
    id: 64,
    name: "Paper Expandator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Paper_Expandator",
  },
  HeavyExpandator: {
    description:
      "The Heavy Expandator medal belongs to those who have done extraordinary deeds to help spread Turf. (more than 500 new downloads within 5 days)",
    id: 23,
    name: "Heavy Expandator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Heavy_Expandator",
  },
  Neutralizer: {
    description:
      "This medal is worn by the neutral-hungry turfer who takes at least 20 neutral zones in 5 hours.",
    id: 16,
    name: "Neutralizer",
    takeTypes: [TakeType.Takeover],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Neutralizer",
  },
  BonanzaGold: {
    country: "se",
    description:
      "The winner of a Turf-Bonanza event will be rewarded this prestige medal. A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 20,
    name: "Bonanza Gold",
    unattainable: true,
    wikiLink: "https://wiki.turfgame.com/en/wiki/Bonanza_Gold",
  },
  BonanzaSilver: {
    country: "se",
    description:
      "This medal goes to Turf-Bonanzas second place. A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 19,
    name: "Bonanza Silver",
    unattainable: true,
    wikiLink: "https://wiki.turfgame.com/en/wiki/Bonanza_Silver",
  },
  BonanzaBronze: {
    country: "se",
    description:
      "This medal goes to Turf-Bonanzas third place. A player can posses more than one of this medal and it can be achieved an unlimited amount of times.",
    id: 18,
    name: "Bonanza Bronze",
    unattainable: true,
    wikiLink: "https://wiki.turfgame.com/en/wiki/Bonanza_Bronze",
  },
  BonanzaParticipator: {
    country: "se",
    description:
      "This medal is awarded to those who have participated in a Turf Bonanza event. It can be assigned any number of times to a player, who then gets another medal of this denomination. Note that if you finish in any of the first three places, you will not receive this medal, but you will have to settle for the medal of nobler value. ",
    id: 17,
    name: "Bonanza Participator",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Bonanza_Participator",
  },
  Eventor: {
    description:
      "This medal is awarded to those who have participated in a Turf event (kind of Bonanza). It can be assigned any number of times to a player, who then gets another medal of this denomination. Distributed only at open event.",
    id: 24,
    name: "Eventor",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Eventor",
  },
  Event24: {
    description:
      "This medal is awarded to the ones who participates in an 24 hour event. It can be assigned any number of times to a player, who then gets another medal of this denomination.",
    id: 113,
    name: "Event24",
    value: "24",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Event24",
  },
  NetherlandExplorer: {
    country: "nl",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of the Netherlands.",
    id: 25,
    name: "Netherland Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Netherland_Explorer",
  },
  SwedenExplorer: {
    country: "se",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of Sweden.",
    id: 26,
    name: "Sweden Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Sweden_Explorer",
  },
  KoreaExplorer: {
    country: "kr",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of Korea.",
    id: 29,
    name: "Korea Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Korea_Explorer",
  },
  DenmarkExplorer: {
    country: "dk",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of Denmark.",
    id: 30,
    name: "Denmark Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Denmark_Explorer",
  },
  NorwayExplorer: {
    country: "no",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of Norway.",
    id: 45,
    name: "Norway Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Norway_Explorer",
  },
  FinlandExplorer: {
    country: "fi",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of Finland.",
    id: 49,
    name: "Finland Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Finland_Explorer",
  },
  UKExplorer: {
    country: "uk",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of the United Kingdom.",
    id: 65,
    name: "UK Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/UK_Explorer",
  },
  GermanyExplorer: {
    country: "de",
    description:
      "This incredible Turfer has taken at least one zone in all the regions of Germany.",
    id: 125,
    name: "Germany Explorer",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Germany_Explorer",
  },
  Aquamen: {
    description:
      "This medal is awarded to those who have taken a water zone between May and October. Can only be taken once.",
    id: 4,
    name: "Order of Aquamen",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Order_of_Aquamen",
  },
  GhostMinute: {
    description:
      "Take a holy zone between 00:00 and 00:01 to get this scary medal. This medal can be achieved only once.",
    id: 70,
    name: "Ghost minute",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Ghost_Minute",
  },
  OrderlyIII: {
    description:
      "This medal is worn by the turfers who have taken a bridge zone, then a holy zone and finished off with a monument zone in 20 minutes. Can only be taken once.",
    id: 77,
    name: "Orderly III",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Orderly_III",
  },
  Ranger: {
    description:
      "This medal is worn by the turfers who have taken at least 25 different nationalpark zones within a period of 12 months. Can only be taken once.",
    id: 76,
    name: "Ranger",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Ranger",
  },
  DawnNinja: {
    description:
      "A medal awarded to the first shadow of the day, who have taken at least three zones between the hours 04:00 AM and 05:00 AM. Revisits do not count.",
    id: 67,
    name: "Dawn Ninja",
    takeTypes: [TakeType.Takeover, TakeType.Assist],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Dawn_Ninja",
  },
  Trainer: {
    description:
      "This medal is worn by the turfers who have taken a trainstation zone daily for 30 days. Can only be taken once.",
    id: 117,
    name: "Trainer",
    takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
    tip: "Visit the <a href='https://warded.se/turf/trainer.php'>Trainer page on warded.se/turf</a> (must be logged in) to see your progress.",
    wikiLink: "https://wiki.turfgame.com/en/wiki/Trainer",
  },
  Revisitor: {
    description:
      "This medal is owned by the great revisitor who revisits at least fifty zones within 5 days. It can only be achieved once and cannot be taken with assist.",
    id: 27,
    name: "Revisitor",
    takeTypes: [TakeType.Revisit],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Revisitor",
  },
  CakeDay: {
    description:
      "This medal is worn by the turfers who have taken at least 10 zones on Turfs 10th birthday 2020-07-10. Could only be taken once.",
    discontinued: true,
    id: 126,
    name: "Cake day",
    takeTypes: [TakeType.Takeover, TakeType.Assist, TakeType.Revisit],
    wikiLink: "https://wiki.turfgame.com/en/wiki/Cakeday",
  },
};

export const GetAllMedals = (): Record<string, MedalDataWithGroupData> => {
  const allMedals: Record<string, MedalDataWithGroupData> = {};

  Object.values(GradedMedals).forEach((gm) => {
    gm.medals.forEach((m) => {
      allMedals[m.id] = { ...m, groupName: gm.name };
    });
  });

  Object.values(SingleMedals).forEach((m) => {
    allMedals[m.id] = { ...m, groupName: null };
  });

  return allMedals;
};

export const GetMedalName = (medal: MedalDataWithGroupData): string => {
  if (medal.name) return medal.name;
  if (medal.groupName && medal.value)
    return `${medal.groupName}-${medal.value}`;

  return medal.descriptionShort || medal.value || "Unknown";
};

export const GetMedalGroupForMedal = (
  medal: MedalData
): MedalGroup | undefined => {
  const medalGroup = Object.values(GradedMedals).find((gm) =>
    gm.medals.some((m) => m.id === medal.id)
  );

  return medalGroup;
};

export const FilterRareMedals = (
  medals: MedalDataWithAcquired[],
  country: string | null,
  turfData: TurfMedalsDto | null
): MedalDataWithAcquired[] => {
  return medals.filter((m) => {
    if (m.unattainable || m.discontinued) {
      return false;
    }
    if (m.country) {
      return m.country === country;
    }

    if (turfData != null) {
      return turfData[m.id]?.totalHolders! > COMMON_MEDALS_HOLDERS_LIMIT;
    }

    return false;
  });
};

export const augmentWithTurfMedalsData = (
  medals: Record<string, MedalData>,
  turfMedalsData: TurfMedalsDto | null
) => {
  if (turfMedalsData == null) {
    return medals;
  }

  const augmentedMedals: Record<string, MedalDataWithTurfData> = { ...medals };

  Object.values(augmentedMedals).forEach((medal) => {
    if (turfMedalsData[medal.id] != null) {
      medal.holders = turfMedalsData[medal.id].holders;
      medal.totalHolders = turfMedalsData[medal.id].totalHolders;
    }
  });

  return augmentedMedals;
};

export const augmentGroupsWithTurfMedalsData = (
  medalGroups: Record<string, MedalGroup>,
  turfMedalsData: TurfMedalsDto | null
) => {
  if (turfMedalsData == null) {
    return medalGroups;
  }

  const augmentedMedals: Record<string, MedalGroupWithTurfData> = {
    ...medalGroups,
  };

  Object.values(augmentedMedals).forEach((mg) => {
    mg.medals.forEach((medal) => {
      if (turfMedalsData[medal.id] != null) {
        medal.holders = turfMedalsData[medal.id].holders;
        medal.totalHolders = turfMedalsData[medal.id].totalHolders;
      }
    });
  });

  return augmentedMedals;
};
