import { createContext } from "react";
import {
  MedalDataWithTurfData,
  MedalGroup,
  TurfMedalsDto,
} from "../Types/MedalTypes";

type TurfMedalsDataContextType = {
  gradedMedals: Record<string, MedalGroup>;
  singleMedals: Record<string, MedalDataWithTurfData>;
  turfMedalsData: TurfMedalsDto | null;
};

const TurfMedalsDataContext = createContext<TurfMedalsDataContextType>(
  {} as TurfMedalsDataContextType
);

export default TurfMedalsDataContext;
