import React, { useEffect, useState } from "react";
import {
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
  Unsubscribe,
} from "firebase/database";
import { Button, Icon, Table } from "tabler-react";

import { db } from "../Services/FirebaseDB";
import UserData from "../Types/UserData";
import Zone from "../Types/Zone";
import dayjs from "../Lib/DateUtils";

const LIMIT_STEP = 5;
const MAX_LIMIT = 20;

interface Props {
  userData: UserData;
}

const LatestZones: React.FC<Props> = ({ userData }) => {
  const [limit, setLimit] = useState(LIMIT_STEP);
  const [zones, setZones] = useState<Zone[]>([]);

  const showMore = () => {
    setLimit((prevLimit) => prevLimit + LIMIT_STEP);
  };

  useEffect(() => {
    let unsub: Unsubscribe = () => {};
    if (userData?.region?.id) {
      const zoneRef = ref(db, `zonesByRegion/${userData.region.id}`);

      const zoneQueryRef = query(
        zoneRef,
        orderByChild("dateCreated"),
        limitToLast(limit)
      );

      unsub = onValue(zoneQueryRef, (snapshot) => {
        if (snapshot.hasChildren()) {
          const zonesList: Zone[] = [];
          snapshot.forEach((child) => {
            zonesList.push({
              ...child.val(),
              key: child.key,
            });
          });
          setZones(zonesList.reverse());
        } else {
          setZones([]);
        }
      });
    }

    return () => unsub();
  }, [limit, userData.id]);

  if (!zones?.length) {
    return null;
  }

  return (
    <div>
      <Table
        cards={true}
        className="table-vcenter table-sm"
        responsive={true}
        striped={true}
      >
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Name</Table.ColHeader>
            <Table.ColHeader>Date Created</Table.ColHeader>
            <Table.ColHeader>Area</Table.ColHeader>
            <Table.ColHeader>Info</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.values(zones).map((z) => (
            <Table.Row key={z.name}>
              <Table.Col>
                <a
                  href={`https://turfgame.com/map/${z.name}`}
                  target="_blank"
                  rel="noreferrer"
                  title=""
                >
                  {z.name} <Icon name="external-link" />
                </a>
              </Table.Col>
              <Table.Col>{dayjs(z.dateCreated).format("LLL")}</Table.Col>
              <Table.Col>{z.region?.area?.name || "-"}</Table.Col>
              <Table.Col>
                <a
                  href={`https://turfgame.com/map/${z.name}`}
                  target="_blank"
                  rel="noreferrer"
                  title="Turfgame.com"
                >
                  <Icon name="map" />
                </a>
                <a
                  className="ml-2"
                  href={`https://warded.se/turf/zones.php?zone=${z.name}`}
                  target="_blank"
                  rel="noreferrer"
                  title="Warded (Turf statistics site)"
                >
                  <Icon name="bar-chart" />
                </a>
              </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {limit < MAX_LIMIT && (
        <div className="m-4">
          <Button onClick={showMore}>Show more</Button>
        </div>
      )}
      <div className="m-4">
        Tip: Warded.se has a{" "}
        <a href="https://warded.se/turf/untaken.php" target="_blank">
          map with all your untaken zones
          <Icon name="external-link" />
        </a>{" "}
        (must be logged in)
      </div>
    </div>
  );
};

export default LatestZones;
