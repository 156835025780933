import React from "react";

type Props = {
  includeVipps?: boolean;
  small?: boolean;
};
const DonateButton: React.FC<Props> = ({ includeVipps, small = false }) => (
  <div className="mb-1">
    <a href="https://ko-fi.com/N4N1VWRUQ" target="_blank">
      <img
        alt="Buy Me a Coffee at ko-fi.com"
        src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
        style={{ border: "0px", height: small ? "36px" : "50px" }}
      />
    </a>
    {includeVipps && (
      <a
        href="https://qr.vipps.no/box/d1c92051-2ab7-4f93-998f-d37f93544883/pay-in"
        target="_blank"
      >
        <img
          alt="Donate with Vipps (Denmark, Finland, Norway or Sweden)"
          className="ml-4 mt-1"
          src="https://qr.vipps.no/box/_next/static/media/vipps-logo.2e64fcc2.svg"
          style={{ border: "0px", height: small ? "28px" : "38px" }}
        />
      </a>
    )}
  </div>
);
export default DonateButton;
