import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

interface Props {
  content: React.ReactNode,
  tooltipContent?: React.ReactNode,
}
const TMScoreCard: React.FC<Props> = ({ content, tooltipContent }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ interactive: true, trigger: "click" });

  return (
    <div className="card">
      <div className="card-body p-3 text-center pointer" ref={setTriggerRef}>
        {content}
      </div>
      {visible && tooltipContent && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default TMScoreCard;
