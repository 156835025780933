import React from "react";
import { NavLink } from "react-router-dom";

import TooltipCard from "./TooltipCard";

interface Props {
  description: string;
  userProgress: number;
  userRank?: number | null;
}
const TMScoreCard: React.FC<Props> = ({
  description,
  userProgress,
  userRank,
}) => {
  return (
    <TooltipCard
      content={
        <>
          <div>
            <em>TM Score</em>
          </div>
          <div className="h1 m-0">{`${
            isNaN(userProgress) ? "-" : userProgress
          }%`}</div>
          <div className="mb-2">
            {userRank ? (
              <NavLink to="/top">{`World #${userRank}`}</NavLink>
            ) : (
              "Total Progress"
            )}
          </div>
          <div className="progress progress-sm">
            <div
              className="progress-bar bg-green"
              style={{
                width: `${userProgress}%`,
              }}
            ></div>
          </div>
        </>
      }
      tooltipContent={
        <>
          <h5>Turf Medals Score</h5>
          <div>
            An overall progress estimate based on most of the stats displayed on
            the site.
          </div>
          {description && (
            <div className="mt-2">
              <div>
                <strong>Details:</strong>
              </div>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
        </>
      }
    />
  );
};

export default TMScoreCard;
