import React, { useState } from "react";
import { PricingCard } from "tabler-react";

type Props = {
  calculating: boolean;
  index: number;
  userFetcher: (username: string, index: number) => void;
  userResetter: (index: number) => void;
};

const CompareUserHeaderCard: React.FC<Props> = ({
  calculating,
  index,
  userFetcher,
}) => {
  const [username, setUsername] = useState<string>("");
  const [editing, setEditing] = useState(false);

  const fetchUser = () => {
    if (editing) setEditing(false);
    if (username) {
      userFetcher(username, index);
      setUsername("");
    }
  };

  return (
    <PricingCard>
      <PricingCard.Category>
        <input
          autoComplete="nickname"
          className="mb-1 w-100"
          disabled={calculating}
          onChange={(e) => setUsername(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.currentTarget.value) {
              fetchUser();
            }
          }}
          name="username"
          placeholder="Turf username ..."
          type="text"
          value={username}
        />
      </PricingCard.Category>
      <div className="text-center mt-4">
        <button
          className="btn btn-secondary btn-block bg-blue text-white"
          disabled={calculating}
          onClick={() => {
            if (username) {
              fetchUser();
            }
          }}
        >
          Add
        </button>
      </div>
    </PricingCard>
  );
};

export default CompareUserHeaderCard;
