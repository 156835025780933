import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import MedalItem from "../Components/MedalItem";
import UserData from "../Types/UserData";
import { GetAllMedals } from "../Lib/Medals";

const ALL_MEDALS = GetAllMedals();
type Props = {
  userData: UserData;
};

const LatestMedals: React.FC<Props> = ({ userData }) => {
  return (
    <ScrollContainer>
      <div
        style={{
          maxHeight: "80px",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {userData?.medals?.length > 0 &&
          userData.medals
            .reverse()
            .map((m, index) => (
              <MedalItem
                acquired
                hideAcquiredStatus
                key={index}
                medal={ALL_MEDALS[m]}
                userData={userData}
              />
            ))}
      </div>
    </ScrollContainer>
  );
};

export default React.memo(LatestMedals);
