import React from "react";
import { Badge } from "tabler-react";

type Props = {
  curView: string;
  forView: string;
  setView: (view: string) => void;
};

const AnchorBadge: React.FC<Props> = ({ curView, forView, setView }) => (
  <a
    href=""
    onClick={(e) => {
      e.preventDefault();
      setView(forView);
      return false;
    }}
  >
    <Badge
      className="mr-1"
      color={curView === forView ? "primary" : "secondary"}
    >
      {forView.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      })}
    </Badge>
  </a>
);

export default React.memo(AnchorBadge);
