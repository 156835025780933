import React from "react";
import { Card } from "tabler-react";

import TopTurfers from "../Components/Top/TopTurfers";
import TopGraphs from "../Components/Top/TopGraphs";

const Top: React.FC = () => {
  return (
    <div>
      <Card className="p-4">
        <TopTurfers />
      </Card>
      <Card className="p-4">
        <TopGraphs />
      </Card>
    </div>
  );
};

export default React.memo(Top);
