import React, { useEffect } from "react";
import { Alert, Card, Icon, Table } from "tabler-react";

const SITES = [
  {
    name: "Statistics",
    sites: [
      {
        name: "Warded.se",
        description:
          "The primary Turf stats page. Also the data source for many other sites.",
        icon: "https://warded.se/favicon.ico",
        link: "https://warded.se/turf",
      },
      {
        name: "Lundkvist.com",
        description:
          "Various stats on regions, areas and more including some mapping functionality.",
        icon: "https://turf.lundkvist.com/image/favicon-32x32.png",
        link: "https://turf.lundkvist.com/",
      },
      {
        name: "Frut",
        description: "A very comprehensive stats page.",
        icon: "https://frut.zundin.se/favicon.ico",
        link: "https://frut.zundin.se/",
      },
      {
        name: "Unique Turfers",
        description:
          "Lets you find turfers that own nearby zones that you haven't taken a zone from yet.",
        link: "http://farstad.se/unikaturfare/",
      },
      {
        name: "T.R.U.F",
        description: "Various zone stats",
        link: "https://truf.davidg.nu/",
      },
      {
        name: "Daily",
        description: "List of everyone that has taken a Daily medal",
        link: "https://daily-chi.vercel.app/",
      },
    ],
  },
  {
    name: "Maps / Zones",
    sites: [
      {
        name: "Turf Map Tool",
        description:
          "Offers advanced map features including downloading data for offline use.",
        icon: "https://turf.urbangeeks.org/favicon.ico",
        link: "http://turf.urbangeeks.org/",
      },
      {
        name: "Turf AOI",
        description: "Shows which part of the city each turfer controls.",
        icon: "https://turf-aoi.matb.se/favicon-32x32.png",
        link: "https://turf-aoi.matb.se/",
      },
      {
        name: "Painthelper",
        description:
          "Turf map painting and zone stats (including unique + never taken)",
        link: "https://painthelper.knzoon.se/",
      },

      {
        name: "Top Turf",
        description: "See who's taken zones in a specific area",
        link: "https://currie.cloud",
      },
    ],
  },
  {
    name: "Expansions / Enhancements",
    sites: [
      {
        name: "Turfbridge",
        description: "Takes the bridge-medal concept to the next level.",
        icon: "https://turfbridge.com/img/Bridge%20Unique.png",
        link: "https://turfbridge.com",
      },
      {
        name: "Turportalen",
        description: "Lets players create their own Turf groups/leagues.",
        icon: "https://turfportalen.se/images/fav/favicon-16x16.png",
        link: "https://turfportalen.se",
      },
    ],
  },
  {
    name: "Apps (iOS / Android / UWP)",
    sites: [
      {
        name: "Turf User Statistics",
        description:
          "Shows statistics for a specified user. Android / Windows.",
        icon: "https://turfuserstatistics.heapplications.com/favicon.ico",
        link: "http://turfuserstatistics.heapplications.com/",
      },
      {
        name: "Turf Notifier",
        description:
          "Lets you know when something important has happened in Turf. Android.",
        icon: "https://play-lh.googleusercontent.com/niFIJ9wzn1mVgaUgG0chvkam0Lts-TQUx3tjnwSb3X4mm7zwnk79o8ZzizipdHgTUC4=w240-h480-rw",
        link: "https://play.google.com/store/apps/details?id=com.solutionplant.turfnotifier",
      },
    ],
  },
  {
    name: "Others",
    sites: [
      {
        name: "Turf Tuner",
        description: "All the in-game chats presented on the web.",
        icon: "https://turftuner.urbangeeks.org/favicon.ico",
        link: "https://turftuner.urbangeeks.org/",
      },
      {
        name: "Turfzone.se",
        description: "User-uploaded pictures of turf zones.",
        link: "https://turfzone.se/",
      },
      {
        name: "Turf Discord",
        description: "English and Swedish discord server for turfers.",
        link: "https://discord.gg/s9Wraw6t",
      },
      {
        name: "Turf German Discord",
        description: "German discord server for turfers.",
        link: "https://discord.gg/9dqDhyBf",
      },
    ],
  },
];

type Props = {};

const Links: React.FC<Props> = () => {
  useEffect(() => {
    document.title = `Links - Turf Medals`;
  }, []);

  return (
    <div>
      {SITES.map((group) => (
        <Card key={group.name}>
          <Card.Header>
            <Card.Title>{group.name}</Card.Title>
          </Card.Header>
          <Table
            cards={true}
            striped={true}
            responsive={true}
            className="table-vcenter"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader className="links-name">Name</Table.ColHeader>
                <Table.ColHeader>Description</Table.ColHeader>
                <Table.ColHeader />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {group.sites.map((site) => (
                <Table.Row key={site.name}>
                  <Table.Col>
                    <strong>
                      <a href={site.link}>
                        {site.icon && (
                          <img
                            alt={site.name}
                            className="mr-2"
                            src={site.icon}
                            style={{
                              maxHeight: "16px",
                              maxWidth: "16px",
                              verticalAlign: "baseline",
                            }}
                          />
                        )}
                        {site.name}
                      </a>
                    </strong>
                  </Table.Col>
                  <Table.Col>{site.description}</Table.Col>
                  <Table.Col className="w-1">
                    <a href={site.link} target="_blank" rel="noreferrer">
                      <Icon name="external-link" />
                    </a>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      ))}

      <Alert type="primary">
        <h4>Something missing?</h4>
        Let me know through the{" "}
        <Alert.Link href="https://forum.turfgame.com/viewtopic.php?t=17464">
          Turf forum thread
        </Alert.Link>
        .
      </Alert>
    </div>
  );
};

export default React.memo(Links);
