import { useRef, useEffect } from 'react';

const useEventListener = <T>(
  event: string,
  handler: (e: CustomEvent<T>) => void,
  opts?: AddEventListenerOptions,
) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = {
      handleEvent: (e: CustomEvent<T>) => savedHandler.current(e)
    };
    window.addEventListener(event as keyof WindowEventMap, eventListener, opts as boolean | AddEventListenerOptions);
    return () => {
      window.removeEventListener(event as keyof WindowEventMap, eventListener);
    };
  }, [event, opts]);
};

export default useEventListener;
