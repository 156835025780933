import React from "react";
import { Grid, StampCard } from "tabler-react";

import TMScoreCard from "./Cards/TMScoreCard";
import LatestMedals from "../Components/LatestMedals";
import { getUserScore } from "../Lib/CompareUtils";
import UserData from "../Types/UserData";

import RankCard from "./Cards/RankCard";

type Props = {
  userData: UserData;
};

const UserCards: React.FC<Props> = ({ userData }) => {
  const { description, score } = getUserScore(userData);
  const userProgress = Math.round(score * 100 * 100) / 100;

  return (
    <>
      <Grid.Row cards={true} className="top-row">
        <Grid.Col width={6} sm={4} lg={2}>
          <RankCard userData={userData} />
        </Grid.Col>
        <Grid.Col width={6} sm={4} lg={2}>
          <TMScoreCard
            description={description}
            userProgress={userProgress}
            userRank={userData.TMRank}
          />
        </Grid.Col>
        <Grid.Col width={6} sm={4} lg={2}>
          <div className="card">
            <div className="card-body p-3 text-center">
              <div>Medals</div>
              <div className="h1 m-0">{userData.medals.length}</div>
              <div className="mb-4">
                ({`${new Set(userData.medals).size} unique`})
              </div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col width={6} sm={4} lg={2}>
          <div className="card">
            <div className="card-body p-3 text-center">
              <div>
                {userData.round ? (
                  <span title={`Current round ${userData.date_month || ""}`}>
                    Round {userData.round}
                  </span>
                ) : (
                  "Current Round"
                )}
              </div>
              <div className="h1 m-0">{userData.place}</div>
              <div className="mb-4">World Position</div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col width={12} sm={8} lg={4}>
          <div className="card">
            <div className="card-body p-3 text-center">
              <div>Latest medals</div>
              <LatestMedals userData={userData} />
            </div>
          </div>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="blue"
            icon="star"
            header={
              <>
                {userData.totalPoints?.toLocaleString()} <small>Points</small>
              </>
            }
            footer={`${userData.points?.toLocaleString()} this round`}
          />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="yellow"
            icon="bar-chart"
            header={
              <>
                {userData.pointsPerHour} <small>PPH</small>
              </>
            }
            footer={`From ${userData.zones.length} zone${
              userData.zones.length === 1 ? "" : "s"
            }`}
          />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="green"
            icon="map-pin"
            header={
              <>
                {userData.taken?.toLocaleString()} <small>Takes</small>
              </>
            }
            footer={`${userData.uniqueZonesTaken?.toLocaleString()} unique`}
          />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="red"
            icon="navigation"
            header={
              <>
                {Math.round(userData.distanceTraveled)?.toLocaleString()}{" "}
                <small>km</small>
              </>
            }
            footer={`Turf-distance`}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default React.memo(UserCards);
