import React from "react";
import { Table } from "tabler-react";

import UserData from "../Types/UserData";

import MedalGroupItem from "./MedalGroupItem";
import Remaining from "./Remaining";
import useMedalsData from "../Hooks/useMedalsData";

type Props = {
  userData: UserData;
};

const GradedMedalsTable: React.FC<Props> = ({ userData }) => {
  const { gradedMedals } = useMedalsData();
  return (
    <Table
      cards={true}
      className="table-vcenter table-sm"
      responsive={true}
      striped={true}
    >
      <Table.Header>
        <Table.Row>
          <Table.ColHeader>Name</Table.ColHeader>
          <Table.ColHeader>Current</Table.ColHeader>
          <Table.ColHeader>Next</Table.ColHeader>
          <Table.ColHeader>Remaining</Table.ColHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.values(gradedMedals).map((g) => (
          <Table.Row key={g.name}>
            <Table.Col>
              {g.discontinued ? (
                <abbr className="text-muted" title="Discontinued">
                  {g.name}
                </abbr>
              ) : (
                g.name
              )}
            </Table.Col>
            <Table.Col>
              <MedalGroupItem medalGroup={g} userData={userData} />
            </Table.Col>
            <Table.Col>
              <MedalGroupItem medalGroup={g} next userData={userData} />
            </Table.Col>
            <Table.Col>
              <Remaining medalGroup={g} userData={userData} />
            </Table.Col>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(GradedMedalsTable);
