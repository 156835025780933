interface IRank {
  name: string;
  points: number;
}

type RanksType = Record<number, IRank>;

export const Ranks: RanksType = {
  0: {
    name: "Newbie",
    points: 0,
  },
  1: {
    name: "Novice Scout",
    points: 195,
  },
  2: {
    name: "Experienced Scout",
    points: 420,
  },
  3: {
    name: "Advanced Scout",
    points: 700,
  },
  4: {
    name: "Master Scout",
    points: 1200,
  },
  5: {
    name: "Scout Elder",
    points: 1900,
  },
  6: {
    name: "Novice Traveller",
    points: 3500,
  },
  7: {
    name: "Experienced Traveller",
    points: 5500,
  },
  8: {
    name: "Advanced Traveller",
    points: 8100,
  },
  9: {
    name: "Master Traveller",
    points: 12000,
  },
  10: {
    name: "World Traveller",
    points: 15000,
  },
  11: {
    name: "Novice Explorer",
    points: 17500,
  },
  12: {
    name: "Experienced Explorer",
    points: 21000,
  },
  13: {
    name: "Advanced Explorer",
    points: 27500,
  },
  14: {
    name: "Master Explorer",
    points: 35000,
  },
  15: {
    name: "Great Explorer",
    points: 43750,
  },
  16: {
    name: "Novice Adventurer",
    points: 53750,
  },
  17: {
    name: "Experienced Adventurer",
    points: 65000,
  },
  18: {
    name: "Advanced Adventurer",
    points: 75000,
  },
  19: {
    name: "Master Adventurer",
    points: 86250,
  },
  20: {
    name: "Super Adventurer",
    points: 100000,
  },
  21: {
    name: "Novice Seeker",
    points: 112500,
  },
  22: {
    name: "Experienced Seeker",
    points: 126250,
  },
  23: {
    name: "Advanced Seeker",
    points: 141250,
  },
  24: {
    name: "Master Seeker",
    points: 158750,
  },
  25: {
    name: "The Seeker",
    points: 181250,
  },
  26: {
    name: "Novice Conquistador",
    points: 200000,
  },
  27: {
    name: "Experienced Conquistador",
    points: 220000,
  },
  28: {
    name: "Advanced Conquistador",
    points: 241250,
  },
  29: {
    name: "Master Conquistador",
    points: 263750,
  },
  30: {
    name: "Grand Conquistador",
    points: 300000,
  },
  31: {
    name: "Novice Zoner",
    points: 350000,
  },
  32: {
    name: "Experienced Zoner",
    points: 410000,
  },
  33: {
    name: "Advanced Zoner",
    points: 480000 ,
  },
  34: {
    name: "Master Zoner",
    points: 560000,
  },
  35: {
    name: "Delicate Zoner",
    points: 650000,
  },
  36: {
    name: "Light Turfer",
    points: 750000,
  },
  37: {
    name: "Novice Turfer",
    points: 860000,
  },
  38: {
    name: "Experienced Turfer",
    points: 980000,
  },
  39: {
    name: "Advanced Turfer",
    points: 1100000,
  },
  40: {
    name: "Turf Master",
    points: 1250000,
  },
  41: {
    name: "Turf Grandmaster",
    points: 1500000,
  },
  42: {
    name: "Turf Guardian",
    points: 1770000,
  },
  43: {
    name: "Turf Knight",
    points: 2060000,
  },
  44: {
    name: "Turf Hero",
    points: 2370000,
  },
  45: {
    name: "Turf Elder",
    points: 2700000,
  },
  46: {
    name: "Turf Preacher",
    points: 3000000,
  },
  47: {
    name: "Turf Lord",
    points: 3500000,
  },
  48: {
    name: "Turf Overlord",
    points: 4000000,
  },
  49: {
    name: "Turf Count",
    points: 4500000,
  },
  50: {
    name: "Turf King",
    points: 5000000,
  },
  51: {
    name: "Turf Tsar",
    points: 5750000,
  },
  52: {
    name: "Turf Caesar",
    points: 6500000,
  },
  53: {
    name: "Amazing Turfer",
    points: 7250000 ,
  },
  54: {
    name: "Incredible Turfer",
    points: 8000000,
  },
  55: {
    name: "Holy Turfer",
    points: 9500000,
  },
  56: {
    name: "Turf Angel",
    points: 11000000,
  },
  57: {
    name: "Turf Archangel",
    points: 13000000,
  },
  58: {
    name: "Turf God",
    points: 15000000,
  },
  59: {
    name: "Turf Titan",
    points: 25000000,
  },
  60: {
    name: "Turfalicious",
    points: 50000000,
  },  
}

export default Ranks;