import { DataValue, CompareItemType } from "../Types/CompareTypes";
import UserData from "../Types/UserData";
import { GradedMedals, SingleMedals } from "./Medals";
import Ranks from "./Ranks";

export const CompareItems: CompareItemType[] = [
  {
    dataGetter: (userData: UserData) => ({ value: userData?.rank }),
    icon: "pocket",
    name: "Rank",
    weight: 20,
    fullScore: Number(Object.keys(Ranks).pop()),
  },
  {
    dataGetter: (userData: UserData) => ({ value: userData?.totalPoints }),
    icon: "star",
    name: "Points",
    weight: 15,
    fullScore: Object.values(Ranks).pop()!.points,
  },
  {
    dataGetter: (userData: UserData) => ({ value: userData?.medals.length }),
    icon: "award",
    name: "Medals",
    weight: 4,
  },
  {
    fullScore:
      Object.values(SingleMedals).length + Object.values(GradedMedals).length,
    dataGetter: (userData: UserData) => ({
      value: new Set(userData?.medals).size,
    }),
    icon: "award",
    name: "Unique medals",
    weight: 3,
  },
  {
    dataGetter: (userData: UserData) => ({ value: userData?.uniqueZonesTaken }),
    icon: "square",
    name: "Unique zones",
    weight: 5,
  },
  {
    dataGetter: (userData: UserData) => ({ value: userData?.zones?.length }),
    icon: "grid",
    name: "Current zones",
    weight: 1,
  },
  {
    dataGetter: (userData: UserData) => ({ value: userData?.place }),
    description: "Current round",
    icon: "globe",
    inverse: true,
    name: "World position",
    weight: 1,
  },
  {
    fullScore: 1,
    medal: "GoldMedal",
    name: "Golds",
    dataGetter: (userData: UserData) => ({
      value: userData?.medals?.filter((m) => m === 1).length,
    }),
    weight: 15,
  },
  {
    fullScore: 1,
    medal: "SilverMedal",
    name: "Silvers",
    dataGetter: (userData: UserData) => ({
      value: userData?.medals?.filter((m) => m === 2).length,
    }),
    weight: 12,
  },
  {
    fullScore: 1,
    medal: "BronzeMedal",
    name: "Bronzes",
    dataGetter: (userData: UserData) => ({
      value: userData?.medals?.filter((m) => m === 3).length,
    }),
    weight: 10,
  },
  {
    fullScore: 1,
    medal: "CountryMedal",
    name: "Country wins",
    dataGetter: (userData: UserData) => ({
      value: userData?.medals?.filter((m) => m === 69).length,
    }),
    weight: 4,
  },
  {
    fullScore: 1,
    medal: "RegionMedal",
    name: "Region wins",
    dataGetter: (userData: UserData) => ({
      value: userData?.medals?.filter((m) => m === 68).length,
    }),
    weight: 3,
  },
  ...Object.values(GradedMedals)
    .filter((g) => g.includeInCompare === true)
    .map((g) => ({
      dataGetter: (userData: UserData) => {
        const medals = g.medals.slice().reverse();
        const foundIndex = medals.findIndex((m) =>
          userData.medals.includes(m.id)
        );
        if (foundIndex === -1) {
          return {
            name: "-",
            value: 0,
          };
        }

        const medal = medals[foundIndex];
        return {
          medals: [medal],
          name: medal.name ?? `${g.name}-${medal.value}`,
          value: medals.length - foundIndex + 1,
        };
      },
      fullScore: g.medals.length + 1,
      medal: g.medals[0].name!,
      name: g.name,
      weight: 2,
    })),
  {
    description: "Non-graded only",
    icon: "award",
    name: "Exclusive medals",
    dataGetter: (userData: UserData, allUsers?: UserData[]) => {
      if (!allUsers || allUsers.length === 1) {
        // N/A - no one to compare to
        return {
          medals: [],
          value: 0,
        };
      }
      const medalsOthers = allUsers
        .filter((u) => u.id !== userData.id)
        .reduce<number[]>((prev, cur) => {
          return prev.concat(cur.medals);
        }, []);

      const singleMedalsArray = Object.values(SingleMedals);

      const exclusiveMedals = new Set(
        userData.medals
          .filter((m) => medalsOthers.indexOf(m) === -1)
          .filter(
            (m) => singleMedalsArray.findIndex((sm) => sm.id === m) !== -1
          )
      );

      const medals = Array.from(exclusiveMedals).map(
        (m) => singleMedalsArray.find((sm) => sm.id === m)!
      );

      return {
        medals,
        value: exclusiveMedals.size,
      };
    },
    weight: 3,
  },
];

export const getPosition = (
  user: UserData,
  allUsers: UserData[],
  dataGetter: (userData: UserData, allUsers: UserData[]) => DataValue,
  inverse?: boolean
) => {
  const data = allUsers
    .filter((u) => u?.id)
    .map((u) => ({ id: u.id, value: dataGetter(u, allUsers).value }))
    .sort((a, b) => (inverse ? a.value - b.value : b.value - a.value));

  if (data.every((val, i, arr) => val.value === arr[0].value)) {
    return -1; // it's a tie!
  }

  const foundPos = data.findIndex((i) => i.id === user.id);
  if (data[foundPos - 1] && data[foundPos - 1].value === data[foundPos].value) {
    return foundPos; // (actually +1-1, but is also just foundpos (tie with the one before)
  }
  return foundPos + 1;
};

export const getUserScore = (user: UserData) => {
  if (!user?.id) return { description: "", score: 0 };

  const compareValues = Object.values(CompareItems).filter(
    (i) => i.fullScore !== undefined
  );

  let sum = 0;
  let sumWeights = 0;
  let itemScore = 0;
  let userScore = 0;
  let description = "";

  compareValues.forEach((i) => {
    itemScore = getItemScore(i, user, [user]);
    userScore =
      itemScore /
      (i.fullScore === 1 && itemScore > 0
        ? i.dataGetter(user, [user]).value
        : i.fullScore!);

    if (userScore / i.weight > 1) {
      // above 100%
      userScore = userScore / (userScore / i.weight);
    }

    sum += userScore;
    sumWeights += i.weight;
    description += `<span title="Weight: ${i.weight}">${i.name}: ${
      Math.round((userScore / i.weight) * 100 * 100) / 100
    }%.</span><br />`;
  });

  description += "<br />";

  return {
    description,
    score: sum / sumWeights,
  };
};

export const getItemScore = (
  item: CompareItemType,
  user: UserData,
  allUsers: UserData[]
) => {
  return item.weight * item.dataGetter(user, allUsers).value;
};

export const getResults = (users: UserData[]) => {
  const compareResults: Record<
    string,
    { positions: number[]; scores: number[] }
  > = {};

  if (users.length === 0 || users[0] == null || !users[0].id) {
    return {
      userPercentages: null,
      winnerId: -1,
    };
  }

  Object.values(CompareItems).forEach((i) => {
    const sum = users
      .filter((u) => u?.id)
      .reduce<number>((prev, u) => prev + i.dataGetter(u, users).value, 0);

    const shouldCount = sum !== 0 && i.weight !== 0;

    compareResults[i.name] = {
      positions: users.map((u) =>
        getPosition(u, users, i.dataGetter, i.inverse)
      ),
      scores: !shouldCount
        ? new Array(users.length).fill(0)
        : users.map((u) => (u?.id ? getItemScore(i, u, users) / sum : 0)),
    };
  });

  const userSums = new Array(users.length).fill(0);

  Object.values(compareResults).forEach((r) => {
    r.scores.forEach((u, index) => (userSums[index] += u));
  });

  const winnerIndex = userSums.indexOf(Math.max(...userSums));

  const sum = userSums.reduce<number>((prev, u) => prev + u, 0);

  const userPercentages = userSums.map((u) => (100 * u) / sum);

  return {
    userPercentages,
    winnerId: users[winnerIndex].id,
  };
};

export const getCompareItemByName = (name: string) =>
  CompareItems.find((i) => i.name === name);
