import React from "react";
import { Icon, Loader, PricingCard } from "tabler-react";

import UserData from "../Types/UserData";
import Username from "./Username";
import { useSupporters } from "../Hooks/useSupporters";

type Props = {
  active?: boolean;
  calculating: boolean;
  index: number;
  user?: UserData | null;
  userPercent?: number;
  userResetter: (index: number) => void;
  winner?: boolean;
};

const CompareUserHeaderCard: React.FC<Props> = ({
  active = false,
  calculating,
  index,
  user,
  userPercent,
  userResetter,
  winner = false,
}) => {
  const supporters = useSupporters();
  if (user?.id) {
    return (
      <PricingCard active={active}>
        <div className="compare-user-remove">
          <Icon onClick={() => userResetter(index)} link name="x" />
        </div>
        <PricingCard.Category>
          <h2 className="text-ellipsis">
            <Username supporters={supporters} username={user.name} />
          </h2>
        </PricingCard.Category>
        {calculating ? (
          <PricingCard.Button
            // active={userPercent && winner}
            // className={userPercent && winner ? "text-white" : ""}
            className="text-center flex-center"
          >
            <Loader />
          </PricingCard.Button>
        ) : (
          <PricingCard.Button
            active={userPercent && winner}
            className={
              userPercent && winner
                ? "cursor-default text-white"
                : "cursor-default"
            }
          >
            {winner &&
              userPercent &&
              `Winner (${Math.round(userPercent * 10) / 10}%)`}
            {!winner && userPercent && `${Math.round(userPercent * 10) / 10} %`}
            {!userPercent && "Waiting ..."}
          </PricingCard.Button>
        )}
      </PricingCard>
    );
  }
  return null;
};

export default React.memo(CompareUserHeaderCard);
