import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { onValue } from "firebase/database";

import { medalsRef } from "../Services/FirebaseDB";
import { TurfMedalsData } from "../Types/MedalTypes";
import Context from "./MedalsDataContext";
import {
  augmentGroupsWithTurfMedalsData,
  augmentWithTurfMedalsData,
  GradedMedals,
  SingleMedals,
} from "../Lib/Medals";

const TurfMedalsDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [turfMedalsData, setTurfMedalsData] = useState<Record<
    number,
    TurfMedalsData
  > | null>(null);

  const gradedMedals = useMemo(
    () => augmentGroupsWithTurfMedalsData(GradedMedals, turfMedalsData),
    [turfMedalsData]
  );

  const singleMedals = useMemo(
    () => augmentWithTurfMedalsData(SingleMedals, turfMedalsData),
    [turfMedalsData]
  );

  useEffect(() => {
    const fetchMedals = () => {
      onValue(medalsRef, (snapshot) => {
        const data = snapshot.val();

        setTurfMedalsData(data);
      });
    };

    fetchMedals();
  }, []);

  return (
    <Context.Provider value={{ gradedMedals, singleMedals, turfMedalsData }}>
      {children}
    </Context.Provider>
  );
};

export default TurfMedalsDataProvider;
