import { Amount } from "../Types/Supporter";

type SupporterStylingData = {
  className: string;
  title: string;
};

export const AMOUNT_1 = "$";
export const AMOUNT_2 = "$$";
export const AMOUNT_3 = "$$$";
export const AMOUNT_4 = "💎";

export const AmountTitles: Record<Amount, string> = {
  [AMOUNT_1]: "$0 - $3",
  [AMOUNT_2]: "$4 - $8",
  [AMOUNT_3]: "$9 - $19",
  [AMOUNT_4]: "$20+",
};

export const GetAmountAttributes = (amount: Amount): SupporterStylingData => {
  let amountClass = "";
  switch (amount) {
    case "$":
      amountClass = "text-blue";
      break;
    case "$$":
      amountClass = "text-lime";
      break;
    case "$$$":
      amountClass = "text-yellow";
      break;
    default:
  }
  const className = "tracking-tight cursor-pointer " + amountClass;
  return {
    className,
    title: AmountTitles[amount],
  };
};
