import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import useEventListener from './useEventListener';

const CUSTOM_STORAGE_EVENT = 'local-storage';

declare global {
  interface WindowEventMap {
    CUSTOM_STORAGE_EVENT: CustomEvent;
  }
}

export interface Options {
  useSessionStorage?: boolean;
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

const getStorage = (options?: Options): Storage => {
  if (options?.useSessionStorage) {
    return window.sessionStorage;
  }
  return window.localStorage;
};

function useLocalStorage<T>(initialValue: T, key: string, options?: Options): [T, SetValue<T>] {
  const keyRef = useRef(key);
  const storage = useRef(getStorage(options));

  // Get from local storage and parse stored json or return initialValue
  const readValue = useCallback((): T => {
    try {
      const item = getStorage(options).getItem(keyRef.current);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading storage key "${keyRef.current}":`, error);
      return initialValue;
    }
  }, [initialValue, options]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that
  // persists the new value to storage.
  const setValue: SetValue<T> = useCallback(
    (value) => {
      // Prevent build error "window is undefined" but keeps working
      if (typeof window == 'undefined') {
        console.warn(
          `Tried setting storage key "${keyRef.current}" even though environment is not a client`
        );
      }

      try {
        // Allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value;

        // Save to local storage
        storage.current.setItem(keyRef.current, JSON.stringify(newValue));

        // Save state
        setStoredValue(newValue);

        // We dispatch a custom event so every useLocalStorage hook are notified
        window.dispatchEvent(new Event(CUSTOM_STORAGE_EVENT));

      } catch (error) {
        console.warn(`Error setting storage key "${keyRef.current}":`, error);
      }
    },
    [storedValue]
  );

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  useEffect(() => {
    const currKey = keyRef.current;
    if (key !== currKey) {
      try {
        storage.current.setItem(key, JSON.stringify(storedValue));
        keyRef.current = key;
        storage.current.removeItem(currKey);
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [key, storedValue]);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

   // this is a custom event for this component, triggered when writing
   useEventListener(CUSTOM_STORAGE_EVENT, handleStorageChange);

  return [storedValue, setValue];
}

export default useLocalStorage;
