import useLocalStorage from "./useLocalStorage";

import { TRACKED_MEDALS_KEY } from "../Lib/Constants";
import useUsername from "./useUsername";

type TrackedMedals = Record<string, Record<string, boolean>>;

const updateTrackedMedal = (trackedMedals: TrackedMedals, username: string, medalId: number, tracked: boolean) => (
  {
    ...trackedMedals,
    [username]: {
      ...trackedMedals[username],
      [medalId]: tracked === true ? true : undefined,
    }
   }
);

function useTrackedMedals() {
  const username = useUsername();

  const [trackedMedals, setTrackedMedals] = useLocalStorage<Record<string, Record<string, boolean>>>({}, TRACKED_MEDALS_KEY);

  const addMedal = (medalId: number) => {
    if (!username) return;

    if (!trackedMedals[username]?.[medalId]) {
      setTrackedMedals(updateTrackedMedal(trackedMedals, username, medalId, true));
    }
  };

  const removeMedal = (medalId: number) => {
    if (!username) return;

    if (trackedMedals[username]?.[medalId]) {
      setTrackedMedals(updateTrackedMedal(trackedMedals, username, medalId, false));
    }
  };

  return { addMedal, removeMedal, trackedMedals };
}

export default useTrackedMedals;